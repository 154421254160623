import React from 'react';
import { storeConnector } from 'store';
import 'components/Battery/OperationsAndMonitoring/SiteUnitComandsPopup/SiteUnitComandsPopup.scoped.scss';

const CommandsExecuteConfirmModal = ({
  commandsToExecute,
  setCommandsToExecute,
  onExecute,
  onCancel,
  actions,
}) => {
  const handleExecuteCommand = () => {
    commandsToExecute.forEach(command => {
      actions.sendCommand(
        [command.command],
        command.sn,
        command.unit,
      );
    });

    setCommandsToExecute([]);

    if (onExecute) {
      onExecute();
    }
  };

  return (
    <div className='cover-conteiner cover-container-confirm'>
      <div className='control-popup-container'>
        <div className='popup-row'>
          {
            <>
              <div className='bold text-center'>Are you sure to execute?</div>
              <div className='normal'>
                <table className='commands-to-execute-table text-center'>
                  <thead>
                    <tr>
                      <th>Command</th>
                      <th>Unit</th>
                    </tr>
                  </thead>
                  <tbody>
                    {commandsToExecute.map((confirmingCommand, index) => (
                      <tr key={`confirming-command-${index}`}>
                        <td>{confirmingCommand.command}</td>
                        <td>
                          {confirmingCommand.unitLabel ? (
                            confirmingCommand.unitLabel
                          ) : (
                            confirmingCommand.unit
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </>
          }
        </div>
        <div className='popup-row commands-popup-control'>
          {
            <button className='bold'
              onClick={handleExecuteCommand}
            >OK</button>
          }
          <button className='bold' onClick={() => {
            setCommandsToExecute([]);

            if(onCancel) {
              onCancel();
            }
          }}>CANCEL</button>
        </div>
      </div>
    </div>
  );
};

export default storeConnector(CommandsExecuteConfirmModal, {});