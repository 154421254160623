const initState = {
  UnitTable: [],
  rackDict: {},
  PCSDict: {},
  batteryDict: {},
  unitTaleLoadedFromDynamo: false,
  currentUnit: null,
  currentPCS: null,
  currentRack: null,
  batteryTable: {},
  pcsTable: {},
  rackAlarmMap: [],
  lotoTable: {},// dict {'sn': 'loto'}
};

export default (state = initState, action) => {
  const handlers = {
    'UPDATE_RACK': () => {
      let newRack = null;
      if(action.rack){
        newRack = action.rack;
        for (let key in newRack) {
          if(typeof newRack[key] === 'string' && newRack[key].includes('[')){
            try{
              newRack[key] = JSON.parse(newRack[key]);
            }catch{
              newRack[key] = newRack[key].replace(/(\[|\])/g,'').split(',');
            }
          }
        }
      }
      return {...state, currentRack: newRack};
    },
    'UPDATE_UNIT': () => {
      const newState = {...state};
      const sns = state.siteMeta.Units;
      let unitTable = state.UnitTable ? JSON.parse(JSON.stringify(state.UnitTable)) : [];
      const nameIndex = sns.findIndex(el => el === action.unit.SN);
      const unit = {...action.unit, UnitName: state.siteMeta.UnitNames[nameIndex]};
      const currentIndex = unitTable.findIndex(u => u.SN === action.unit.SN);
      if (currentIndex === -1) {
        unitTable.push(unit);
        unitTable = unitTable.sort((a,b) => {
          const aIndex = sns.findIndex(el => el === a.SN);
          const bIndex = sns.findIndex(el => el === b.SN);
          if (aIndex > bIndex) {
            return 1;
          }
          if (aIndex < bIndex) {
            return -1;
          }
          return 0;
        });
      } else {
        unitTable[currentIndex] = unit;
      }
      newState.UnitTable = unitTable;
      return newState;
    },
    'UPDATE_BATTERY': () => {
      const newState = {...state};
      newState.batteryTable[action.battery.SN] = action.battery;
      return newState;
    },
    'UPDATE_PCS': () => {
      const newState = {...state};
      newState.pcsTable[action.pcs.SN] = action.pcs;
      return newState;
    },
    'UPDATE_DEVICES_MODULE': () => {
      delete action.type;
      return {...state, ...action};
    },
  };
  if (!handlers[action.type])
    return state;
  return handlers[action.type]();
};