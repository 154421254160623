import { callRESTThrottled } from 'utils';
import { dispatcher, store } from 'store';
import { site } from 'store/actionCreators/site';

export const vpps = {
  selectVppSite(sn) {
    return function (dispatch) {
      dispatch(dispatcher('SELECT_VPP_SITE', { sn }));
    };
  },
  deselectVppSite(sn) {
    return function (dispatch) {
      let arr = store.getState().vpp.VPPsSitesSelected;
      if (!sn) arr = [];
      arr.remove(sn);
      dispatch(dispatcher('DESELECT_VPP_SITE', { sn }));
    };
  },
  getVPP() {
    return function (dispatch) {
      dispatch(dispatcher('UPDATE_SITE_MODULE', { currentSite: null }));
      dispatch(dispatcher('LOADING', { loadVPP: true }));
      callRESTThrottled('POST', '/config/v2/serve', {
        action: 'getVPP'
      }).then((data) => {
        dispatch(dispatcher('UPDATE_VPP_MODULE', { VPPs: data.result }));
        let siteSNs = data.result.Sites;
        if (!Array.isArray(siteSNs)) {
          siteSNs = siteSNs.split(',');
        }
        dispatch(dispatcher('UPDATE_VPP_MODULE', { VPPsSitesSNs: siteSNs }));
        dispatch(dispatcher('LOADING', { loadVPP: false }));
        Promise.all(
          [store.dispatch(site.loadAllSiteMeta(siteSNs, true))]
        ).then(() => {

          const snsWithSiteMeta = Object.keys(store.getState().vpp.VPPsSitesMeta);
          Promise.all(
            snsWithSiteMeta.map(sn => store.dispatch(site.loadFlatSiteData(sn, '1H', 'Site')))
          ).then((data) => {
            dispatch(dispatcher('UPDATE_VPP_MODULE', {
              VPPSiteData: data.reduce(
                (obj, dataArr, i) => {
                  obj[snsWithSiteMeta[i]] = dataArr;
                  return obj;
                }, {}
              ),
            }));
          });

        });
      });
    };
  },
  returnVPPsMeta() {
    return function (dispatch) {
      return new Promise((resolve) => {
        callRESTThrottled('POST', '/config/serve', {
          action: 'getTableItems',
          table: 'Site_Meta',
          extraParams: {
            'ScanIndexForward': false,
            'ExpressionAttributeValues': {
              ':v': 'current',
            },
            'KeyConditionExpression': 'SN = :v',
          },
        }).then(r => {
          resolve(r);
        });
      });
    };
  },
};