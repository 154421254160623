import React, { useEffect, useState } from 'react';
import { storeConnector } from 'store';
import styles from 'components/elements/clock/Clock.module.css';
import { optionsSelectTZ, tsDueTimeMode } from 'utils';
import Input from 'components/elements/Input/Input';

const Clock = (props) => {
  const [, setRenderKey] = useState(0);

  const setTimeMode = (val) => {
    props.actions.setTimeMode(val);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setRenderKey({renderKey: Math.random()});
    }, 500);
    return () => clearInterval(interval);
  }, []);

  const getCurrentTime = () => {
    return tsDueTimeMode({
      timeMode: props.timeMode,
      lat: props.siteMeta.GPSLat,
      long: props.siteMeta.GPSLong,
      splited: true,
    });
  };

  const tsArr = getCurrentTime();
  return (
    <div className={styles.container}>
      <div className={styles.selectRow}>
        <span>Time Mode:</span>
        <Input
          type='dropdown'
          placeholder='Select Device'
          ddMaxHeight='260'
          value={{value: props.timeMode}}
          styleDD={{
            maxWidth: '60px',
            minWidth: '60px',
            marginRight: 'auto',
          }}
          onChange={(val) => setTimeMode(optionsSelectTZ[val])}
          options={ optionsSelectTZ }
        />
      </div>
      <div className={styles.time}>
        <div>{tsArr[0]}</div>
        <div>
          <div>{tsArr[1]}</div>
          <div style={{
            height: 5,
            background: 'green',
            borderRadius: 5,
            visibility: props.updateFinished ? 'visible' : 'hidden'
          }} />
        </div>
      </div>
    </div>
  );
};

export default storeConnector(Clock, {
  user: ['timeMode'],
  site: ['siteMeta'],
  service: ['updateFinished'],
});