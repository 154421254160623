import React from 'react';
import 'components/UserPanel/UserPanel.scoped.scss';
import { storeConnector } from 'store';
import { ReactComponent as LoginIcon } from 'assets/login.svg';

const UserPanel = (props) => {
  return (
    <div className={props.showUserPanel ? 'user-panel open' : 'user-panel'}>
      <div className='popup-close' onClick={() => props.close()} />
      {
        props.siteMeta && props.currentSite && props.siteMeta.FastStop_Enable &&
        <>
          <div>Send site command</div>
          <div className='switch'>
            <div className={'fast-stop-btn'} onClick={() => props.openFastStopPopup()}>
              Fast Stop
            </div>
          </div>
        </>
      }
      <LoginIcon className='icon login' />
      <div className='user-info'>
        <div className='bold'>{props.user.username}</div>
        <div>{props.user.email}</div>
        <div>{props.user.group}</div>
        <button className='logout-btn' onClick={() => props.actions.logout()}>Logout</button>
      </div>
      <div className={'links'}>
        <h6>Links</h6>
        <div onClick={() => props.openChangePasswdPopup()}>Change password</div>
        <div onClick={() => props.openSettingsPopup()}>Settings</div>
        {
          props.user.group === 'Administrator' ?
            <div onClick={() => {
              props.history.push(props.currentPath.startsWith('/admin') ? '' :'/admin');
            }}>
              {
                props.currentPath.startsWith('/admin') ?
                  'Fleet Manager' : 'Admin Panel'
              }
            </div>
            : <></>
        }
        <div onClick={() => props.openSupportPopup()}>Support</div>
      </div>
    </div>
  );
};

export default storeConnector(UserPanel, {
  service: ['currentPath', 'history'],
  user: ['user'],
  site: ['siteMeta', 'currentSite'],
});