import React, { useMemo, useRef, useState } from 'react';
import { storeConnector } from 'store';
import 'components/Battery/OperationsAndMonitoring/SiteUnitComandsPopup/SiteUnitComandsPopup.scoped.scss';
import Input from 'components/elements/Input/Input';

const MinTargetSoc = 0;
const MaxTargetSoc = 100;

const CellBalancingSection = ({
  setOverInput,
  showExecuteCommandsPopup,
  popupProps,
}) => {
  const unitData = popupProps.obj;

  const [mode, setMode] = useState(unitData?.CellBalancing_State === 'Cell Balancing');
  const [targetSoc, setTargetSoc] = useState(0);
  const [duration, setDuration] = useState(0);

  const targetSocRef = useRef();
  const durationRef = useRef();

  const isValidTargetSoc = useMemo(() => (
    MinTargetSoc <= +targetSoc
    && MaxTargetSoc >= +targetSoc
  ),[
    targetSoc,
  ]);
  const isValidInput = useMemo(() => (
    isValidTargetSoc
  ), [
    isValidTargetSoc,
  ]);
  const cellBalancingAvailable = useMemo(() => (
    unitData?.InvStatus === 'RunPQ'
      && unitData.RunMode === 'Auto'
  ), [
    unitData
  ]);

  const onEnter = (index) => {
    if (index === 0) {
      durationRef.current.focus();
    } else if (index === 1) {
      handleSend();
    }
  };

  const handleSend = () => {
    if (!isValidInput || !cellBalancingAvailable) {
      return;
    }

    const commandsToExecute = [
      {
        command: `CellBalancing::${mode ? '1' : '0'}::${targetSoc}::${duration}`,
        sn: unitData?.SN,
        unitLabel: unitData?.UnitName,
      }
    ];
    showExecuteCommandsPopup(
      commandsToExecute,
    );
  };

  return (
    <div className='cell-balancing-section-wrapper'>
      <div className='popup-table'>
        <div className='popup-table-col'>
          <div style={{ lineHeight: '27px' }}>Minutes Remaining</div>
          <div className='range-lable' />
          <div style={{ lineHeight: '27px' }}>Mode</div>
          <div className='range-lable' />
          <div>Target SOC</div>
          <div className='range-lable' />
          <div>Duration</div>
          <div className='range-lable' />
        </div>
        <div className='popup-table-col'>
          <div>
            <div style={{ lineHeight: '27px' }}>
              {
                unitData.CellBalancing_MinutesRemaining ?
                  `${parseInt(unitData.CellBalancing_MinutesRemaining)} Mins` : '-'
              }
            </div>
            <div className='range-lable' />
          </div>
          <div>
            <Input
              onChange={() => setMode(val => !val)}
              checked={mode}
              type='toggle'
              label='mode'
            />
            <div className='range-lable' />
          </div>
          <div>
            <input
              ref={targetSocRef}
              onKeyDown={(e) => {
                if (e.keyCode === 13) {
                  e.preventDefault();
                  onEnter(0);
                }
              }}
              className={
                'comand-popup-inp ' +
                (!isValidTargetSoc ? 'error' : '')
              }
              type='number'
              step='0.1'
              value={targetSoc}
              onMouseEnter={() => setOverInput(true)}
              onMouseLeave={() => setOverInput(false)}
              onChange={e => setTargetSoc(e.target.value)}
            />
            <div className='range-lable'>
              Range from {MinTargetSoc} to {MaxTargetSoc}
            </div>
          </div>
          <div>
            <input
              ref={durationRef}
              className='comand-popup-inp'
              type='number'
              step='0.1'
              onKeyDown={(e) => {
                if (e.keyCode === 13) {
                  e.preventDefault();
                  onEnter(1);
                }
              }}
              value={duration}
              onMouseEnter={() => setOverInput(true)}
              onMouseLeave={() => setOverInput(false)}
              onChange={e => setDuration(e.target.value)}
            />
          </div>
        </div>
        <div className='popup-table-col'>
          <div style={{ height: '27px' }} />
          <div className='range-lable' />
          <div style={{ height: '27px' }} />
          <div className='range-lable' />
          <div>%</div>
          <div className='range-lable' />
          <div>Minutes</div>
          <div className='range-lable' />
        </div>
      </div>

      {!cellBalancingAvailable && (
        <div className='popup-row warn-alert'>
          Cell Balancing Mode can only be enabled when the unit is in RunPQ/Auto.
        </div>
      )}

      <div className='popup-row commands-popup-control'>
        <button
          className='bold'
          disabled={!isValidInput || !cellBalancingAvailable}
          onClick={handleSend}
        >
          Send
        </button>
      </div>
    </div>
  );
};

export default storeConnector(CellBalancingSection, {
  service: ['popupProps'],
  devices: ['UnitTable'],
});
