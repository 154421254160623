import React, { useEffect, useRef, useState } from 'react';
import 'components/Battery/OperationsAndMonitoring/SiteUnitComandsPopup/SiteUnitComandsPopup.scoped.scss';
import { storeConnector } from 'store';
import Input from 'components/elements/Input/Input';
import { tsDueTimeMode } from 'utils';
import Draggable from 'react-draggable';
import SiteConverterPowerCommandForm from './SiteConverterPowerCommandForm';

const UNITS_CMD = 'Unit Power Cmd';

const CommandsSection = (props) => {
  let initialMode = '';
  if(props.popupProps.popupType === 'unit'){
    initialMode = props.popupProps.obj['RunMode'] || props.popupProps.obj['Run Mode'];

  }else if(props.popupProps.popupType === 'site'){
    initialMode = props.popupProps.obj.LocRemCtl || 'Auto';
  }

  const [mode, setMode] = useState(initialMode);
  const [reactive, setReactive] = useState(+props.popupProps.obj.Qset || 0);
  const [real, setReal] = useState(+props.popupProps.obj.Pset || 0);
  const [unitReactive, setUnitReactive] = useState(0);
  const [unitReal, setUnitReal] = useState(0);
  const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);
  const [confirmationPopupChecklist, setConfirmationPopupChecklist] = useState(false);
  const [commands, setCommands] = useState([]);
  const [commandsUnit, setCommandsUnit] = useState();
  const [itemsSelected, setItemsSelected] = useState([]);
  const [multiselectItems, setMultiselectItems] = useState([]);
  const [afterSelectConfirmDlg, setAfterSelectConfirmDlg] = useState(false);
  const [lotoPopupIsOpen, setLotoPopupIsOpen] = useState(false);
  const [lotoReason, setLotoReason] = useState('');
  const [lotoConfirm, setLotoConfirm] = useState(false);
  const [lotoReleaseActive, setLotoReleaseActive] = useState(false);
  const [lotoReleaseNotes, setLotoReleaseNotes] = useState('');
  const [lotoReleaseConfirm, setLotoReleaseConfirm] = useState(false);
  const [type, setType] = useState('');
  const inputFirstRef = useRef();
  const inputSecondRef = useRef();
  const inputThirdRef = useRef();
  const isConfirmLOTORef = useRef(false);

  const titleMultiselectDlg = (commandName) => {
    return `Choose ${props.popupProps.popupType === 'site' ? 'units': 'racks'}\
    to execute command ${commandName ? commandName : commands[0]}.
    `;
  };

  const rackConfirmStr = () => {
    let str = ' for ' + (props.popupProps.popupType === 'site' ? 'unit': 'rack');
    if (itemsSelected.filter(el => !!el).length > 1) {
      str+='s';
    }
    multiselectItems.forEach((r,i) => {
      if (itemsSelected[i] && itemsSelected[i] !== 'disabled') {
        str += ` ${r},`;
      }
    });
    str = str.slice(0,-1);
    return str;

  };
  const showConfPopup = async (com, type, isMulti, unit = undefined) => {
    setShowConfirmationPopup(true);
    setCommands([com]);
    setCommandsUnit(unit);
    setType(type);
    setConfirmationPopupChecklist(!!isMulti);
    const forSite = props.popupProps.popupType === 'site';
    if (isMulti && !forSite) {
      props.setLoading(true);
      const sn = props.UnitTable.find(u => u.SN === unitSN).SN;
      const rackObj = await props.actions.getRack({SN: sn}, true);
      const arr = rackObj.RackMode.replace(/(\[|\])/g,'').split(',');
      setMultiselectItems(arr.map((e, index) =>
        (index < 9 ? '0' : '') + (index + 1)
      ));
      if (['Rack Open', 'Rack Close'].includes(com)) {
        setItemsSelected(arr.map(el =>
          (com === 'Rack Open' ? el === 0 : el !== 0)
        ));
      } else {
        setItemsSelected(Array(arr.length).fill(false));
      }
    }
    if(isMulti && forSite) {
      props.setLoading(true);
      const arr = props.UnitTable.map(u => u.UnitName);
      setMultiselectItems(arr);
      if (com === 'Stop Units') {
        setItemsSelected(props.UnitTable.map(el => {
          if (props.lotoTable[el.SN]) {
            return 'disabled';
          }
          return !(['RunPQ', 'RunUF'].includes(el['InvStatus']) || ['RunPQ', 'RunUF']
            .includes(el['Inv Status']));
        }));
      } else if (com === 'Start Units') {
        setItemsSelected(props.UnitTable.map(el => {
          if (props.lotoTable[el.SN]) {
            return 'disabled';
          }
          return (['RunPQ', 'RunUF'].includes(el['InvStatus']) || ['RunPQ', 'RunUF']
            .includes(el['Inv Status']));
        }));
      } else if (com === 'Units to Manual') {
        setItemsSelected(props.UnitTable.map(el => {
          if (props.lotoTable[el.SN]) {
            return 'disabled';
          }
          return [el['RunMode'], el['Run Mode']].includes('Manual');
        }));
      } else if (com === 'Units to Auto') {
        setItemsSelected(props.UnitTable.map(el => {
          if (props.lotoTable[el.SN]) {
            return 'disabled';
          }
          return [el['RunMode'], el['Run Mode']].includes('Auto');
        }));
      } else if (com === 'Open AC Breakers') {
        setItemsSelected(props.UnitTable.map(el => {
          if (props.lotoTable[el.SN]) {
            return 'disabled';
          }
          return [el['ACBreaker'], el['AC Breaker']].includes('0');
        }));
      } else if (com === 'Close AC Breakers') {
        setItemsSelected(props.UnitTable.map(el => {
          if (props.lotoTable[el.SN]) {
            return 'disabled';
          }
          return ![el['ACBreaker'], el['AC Breaker']].includes('0');
        }));
      }
      else {
        setItemsSelected(props.UnitTable.map(el => props.lotoTable[el.SN] ? 'disabled' : false));
      }
      if (UNITS_CMD === com) {
        setItemsSelected(props.UnitTable.map(el => {
          const isManual = [el['RunMode'], el['Run Mode']].includes('Manual');
          return !isManual || props.lotoTable[el.SN] ? 'disabled' : false;
        }));
      }
    }
    props.setLoading(false);
  };
  const changeMode = (e) => {
    showConfPopup(`System Mode::${e.target.value}`, 'mode');
  };
  const onChangeReal = (value) => setReal(value);
  const submitPopup = () => {
    if (afterSelectConfirmDlg && confirmationPopupChecklist) {
      if(props.popupProps.popupType === 'site') {
        props.UnitTable.forEach((el,i) => {
          if (itemsSelected[i] === true) {
            const comms = commands[0] === UNITS_CMD ?
              [`Power::${unitReal}::${unitReactive}`] : commands;
            props.actions.sendCommand(comms, el.SN, commandsUnit);
          }
        });
      } else {
        props.actions.sendCommand(
          [`${commands[0]}::[${itemsSelected.map((el, i) =>
            (el && el !== 'disabled') ? i : '').filter(e => e !== '').join(',')}]`],
          props.popupProps.obj.SN,
          props.popupProps.isPV ? unitName : null
        );
      }
      setAfterSelectConfirmDlg(false);
      setConfirmationPopupChecklist(false);
      setShowConfirmationPopup(false);
      setCommands([]);
      setCommandsUnit(undefined);
      setMultiselectItems([]);
      setItemsSelected([]);
    } else if (!confirmationPopupChecklist && !afterSelectConfirmDlg) {
      const converterOrUnitName = props.popupProps.isPV ? unitName : null;
      props.actions.sendCommand(commands, props.popupProps.obj.SN, converterOrUnitName);
    }

    if (type === 'mode') {
      const idx = commands[0].lastIndexOf(':');
      setMode(commands[0].substring(idx+1));
    }
  };
  const onChangeReactive = (value) => setReactive(value);
  const onEnter = (key) => {
    if (key === 0) {
      inputSecondRef.current.focus();
    }
    if (key === 1) {
      if (real !== '' &&
          reactive !== '' &&
          real > allowedMinReal &&
          real < allowedMaxReal &&
          reactive > allowedMinReactive &&
          reactive < allowedMaxReactive
      ) {
        showConfPopup(`Power::${real}::${reactive}`, 'value');
      } else {
        if (real !== '' && real > allowedMinReal &&
        real < allowedMaxReal) {
          inputSecondRef.current.focus();
        } else {
          inputFirstRef.current.focus();
        }
      }
    }
    if (key === 2) {
      inputThirdRef.current.focus();
    }
  };

  const getCommandsList = () => {
    if (props.siteMeta && props.popupProps) {
      const siteCommandsIsArray = Array.isArray(props.siteMeta.Site_Commands);
      const unitCommandsIsArray = Array.isArray(unitCommands);
      let unitCommandsArray = [];

      if(unitCommandsIsArray) {
        unitCommandsArray = unitCommands.map(k => {
          return {
            label: k.Name,
            value: k.Command,
            multiselect: false,
          };
        });
      } else {
        unitCommandsArray = (unitCommands ?
          Object.keys(unitCommands).map(k => {
            return {
              label: k,
              value: unitCommands[k],
              multiselect: false,
            };
          }) :
          []);
      }

      if (props.popupProps.popupType === 'site') {
        if(siteCommandsIsArray) {
          return props.siteMeta.Site_Commands.map(k => {
            return {
              label: k.Name,
              value: k.Command,
              unit: k.Unit,
              multiselect: k.Device === 'Unit',
            };
          });
        } else {
          return props.siteMeta.Site_Commands ?
            Object.keys(props.siteMeta.Site_Commands).map(k => {
              return {
                label: k,
                value: props.siteMeta.Site_Commands[k],
                multiselect: k !== 'Fault Reset',
              };
            }) :
            [];
        }
      } else {
        return [
          ...unitCommandsArray,
          ...(props.siteMeta.Rack_Commands ?
            Object.keys(props.siteMeta.Rack_Commands).map(k => {
              return {
                label: k,
                value: props.siteMeta.Rack_Commands[k],
                multiselect: true,
              };
            }) :
            []
          ),
        ];
      }
    }
    return [];
  };

  const confirmLOTO = (tagout) => {
    const unit = props.UnitTable.find(u => u.SN === unitSN);
    if (unit) {
      isConfirmLOTORef.current = true;
      if (tagout) {
        setLotoReleaseConfirm(false);
      } else {
        setLotoConfirm(false);
      }
    }
  };

  useEffect(() => {
    if (isConfirmLOTORef.current === true) {
      isConfirmLOTORef.current = false;
      const unit = props.UnitTable.find(u => u.SN === unitSN);
      if (unit) {
        (async () => {
          await props.actions.setLOTO({
            SiteSN: props.siteMeta.SN,
            UnitSN: unit.SN,
            Note: lotoReleaseNotes,
            mode: 'tagout',
          });
          setLotoReleaseNotes('');
          setLotoReason('');
        })();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lotoReleaseConfirm]);
  useEffect(() => {
    if (isConfirmLOTORef.current === true) {
      isConfirmLOTORef.current = false;
      const unit = props.UnitTable.find(u => u.SN === unitSN);
      if (unit) {
        (async () => {
          await props.actions.setLOTO({
            SiteSN: props.siteMeta.SN,
            UnitSN: unit.SN,
            Note: lotoReason,
            mode: 'lockout',
          });
          setLotoReleaseNotes('');
          setLotoReason('');
        })();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lotoConfirm]);

  const getLoto = () => {
    if (props.popupProps.popupType === 'unit') {
      const unit = props.UnitTable.find(u => u.SN === unitSN);
      if (unit) {
        const loto = props.lotoTable[unit.SN];
        if (loto) {
          return loto;
        }
        return false;
      }
      return false;
    }
    return false;
  };

  const getCurrentTime = (ts) => {
    return tsDueTimeMode({
      timeMode: props.timeMode,
      lat: props.siteMeta.GPSLat,
      long: props.siteMeta.GPSLong,
      ts,
    });
  };

  const getCheckboxState = (i) => {
    return itemsSelected[i] === true ? 'act' : '';
  };

  const unitCommands = props.popupProps.isPV ? props.siteMeta?.UnitPV_Commands :
    props.siteMeta?.Unit_Commands;

  const unitName = props.popupProps.obj.UnitName;
  const unitSN = props.popupProps.obj.SN;
  const allowedMinReal = props.popupProps.obj.allowedMinReal ||
    +props.popupProps.obj.AllowedMinP || -3;
  const allowedMaxReal = props.popupProps.obj.allowedMaxReal ||
    +props.popupProps.obj.AllowedMaxP || 10;
  const allowedMaxReactive = props.popupProps.obj.allowedMaxReactive ||
    +props.popupProps.obj.AllowedMaxQ || 10;
  const allowedMinReactive = props.popupProps.obj.allowedMinReactive ||
    +props.popupProps.obj.AllowedMinQ || -3;
  const commandsList = [
    ...(props.popupProps?.popupType === 'site' ? [{
      label: UNITS_CMD,
      value: UNITS_CMD,
      multiselect: true
    }] : []),
    ...getCommandsList(),
  ];
  const siteCommandsIsArray = Array.isArray(props.siteMeta?.Site_Commands);
  const unitCommandsIsArray = Array.isArray(unitCommands);
  let allCommandsList;
  if(siteCommandsIsArray && unitCommandsIsArray) {
    allCommandsList = props.siteMeta?.Site_Commands
      .concat(unitCommands);
  }
  const commandName = allCommandsList?.find(el => el.Command === commands[0])?.Name;
  const loto = getLoto();
  return (
    <>
      <div className='commands-popup-content'>
        {
          loto ?
            <div className='release-container'>
              {
                !lotoReleaseActive ?
                  <>
                    <button
                      onClick={()=>setLotoReleaseActive(true)}
                    >
                  Release Controls LOTO
                    </button>
                  </>
                  :
                  <>
                    <div className='loto-release-title'>
                  Release Controls LOTO
                    </div>
                    <div className='loto-release-info'>
                      <div>LOTO Start:</div>
                      <div>{loto.TS ? getCurrentTime(loto.TS) : ''}</div>
                    </div>
                    <div className='loto-release-info'>
                      <div>Operator:</div>
                      <div>{loto.Operator}</div>
                    </div>
                    <div className='loto-release-info'>
                      <div>Notes:</div>
                      <div>{loto.Note}</div>
                    </div>
                    <div className='loto-release-info'>
                      <div>Release Notes:</div>
                      <Input
                        type='text'
                        onMouseEnter={() => props.setOverInput(true)}
                        onMouseLeave={() => props.setOverInput(false)}
                        value={lotoReleaseNotes}
                        onChange={(val)=>setLotoReleaseNotes(val)}
                        placeholder={'Enter reason'}
                      />
                    </div>
                    <div className='popup-row commands-popup-control'
                      style={{marginTop: '50px'}}
                    >
                      <button disabled={!lotoReleaseNotes} className='bold'
                        onClick={() => setLotoReleaseConfirm(true)}
                      >
                    TAGOUT
                      </button>
                      <button className='bold'
                        onClick={() => setLotoReleaseActive(false)}
                      >CANCEL</button>
                    </div>
                  </>
              }
            </div>
            :
            <>
              <div className='popup-content-col'>
                <div className='popup-row mode' onChange={(e) => changeMode(e)}>
                  <div className='bold'>Mode</div>
                  <div className='comand-popup-row'>
                    <label className='inp-container' htmlFor='popupModeA'>
                      <input
                        type='radio'
                        value='Auto'
                        id='popupModeA'
                        checked={mode === 'Auto'}
                        name='mode'
                      />
                      <div>Auto</div>
                    </label>
                    <label className='inp-container' htmlFor='popupModeM'>
                      <input
                        type='radio'
                        value='Manual'
                        id='popupModeM'
                        name='mode'
                        checked={mode === 'Manual'}
                      />
                      <div>Manual</div>
                    </label>
                    {
                      props.popupProps.popupType === 'unit' && unitName &&
                      !props.popupProps.isPV &&
                      <label className='inp-container' htmlFor='popupModeT'>
                        <input
                          type='radio'
                          value='Target SOC'
                          id='popupModeT'
                          name='mode'
                          checked={mode === 'Target SOC'}
                        />
                        <div>Target SOC</div>
                      </label>
                    }
                  </div>
                </div>
                {
                  mode === 'Manual' && (
                    <>
                      <div className='popup-row'>
                        <div className='bold vertical-margin'>
                          {props.popupProps.popupType === 'unit' ? 'Unit Power Command' : 'Power'}
                        </div>
                        <div className='popup-table'>
                          <div className='popup-table-col'>
                            <div>Real (P):</div>
                            <div className='range-lable' />
                            <div>Reactive (Q):</div>
                            <div className='range-lable' />
                          </div>
                          <div className='popup-table-col'>
                            <div>
                              <input ref={inputFirstRef}
                                onKeyDown={(e) => {
                                  if (e.keyCode === 13) {
                                    onEnter(0);
                                    e.preventDefault();
                                  }
                                }}
                                className={'comand-popup-inp '+
                                (real<allowedMinReal?
                                  'error':(real>allowedMaxReal?'error':''))}
                                type='number' step='0.1' value={real}
                                onMouseEnter={() => props.setOverInput(true)}
                                onMouseLeave={() => props.setOverInput(false)}
                                onChange={e => onChangeReal(e.target.value)}
                              />
                            </div>
                            <div className='range-lable'>
                              Range from {allowedMinReal} to {allowedMaxReal}
                            </div>
                            <div>
                              <input ref={inputSecondRef}
                                onKeyDown={(e) => {
                                  if (e.keyCode === 13) {
                                    onEnter(1);
                                    e.preventDefault();
                                  }
                                }}
                                className={'comand-popup-inp '+
                                  (reactive<allowedMinReactive? 'error':
                                    (reactive>allowedMaxReactive?'error':''))
                                }
                                type='number' step='0.1' value={reactive}
                                onMouseEnter={() => props.setOverInput(true)}
                                onMouseLeave={() => props.setOverInput(false)}
                                onChange={e => onChangeReactive(e.target.value,'reactive')}
                              />
                            </div>
                            <div className='range-lable'>
                              Range from {allowedMinReactive} to&nbsp;
                              {allowedMaxReactive}
                            </div>
                          </div>
                          <div className='popup-table-col'>
                            <div>kW</div>
                            <div className='range-lable' />
                            <div>kvar</div>
                            <div className='range-lable' />
                          </div>
                        </div>
                      </div>
                      {(real<allowedMinReal || real>allowedMaxReal) && (
                        <div className='popup-row warn-alert'>
                          The value of real power must be between {allowedMinReal
                          } and {allowedMaxReal}
                        </div>
                      )}
                      {(reactive<allowedMinReactive || reactive > allowedMaxReactive) && (
                        <div className='popup-row warn-alert'>
                          The value of reactive power must be between {allowedMinReactive
                          } and {allowedMaxReactive}
                        </div>
                      )}
                      <div className='popup-row commands-popup-control'>
                        <button className='bold'
                          disabled={
                            real===''||
                            reactive===''||
                            real<allowedMinReal||
                            real>allowedMaxReal||
                            reactive<allowedMinReactive||
                            reactive>allowedMaxReactive
                          }
                          onClick={() => showConfPopup(`Power::${real}::${reactive}`, 'value')}
                        >
                          Send
                        </button>
                      </div>
                      {props?.siteMeta?.NumConverters !== undefined
                        && +props?.siteMeta?.NumConverters > 0
                        && props.popupProps.popupType === 'site'
                        && (
                          <SiteConverterPowerCommandForm
                            showConfPopup={showConfPopup}
                            setOverInput={props.setOverInput}
                          />
                        )}
                    </>
                  )
                }
              </div>
              {
                commandsList.length ?
                  <div className='popup-content-col comands popup-row'>
                    <div className='bold'>Commands</div>
                    <div className='popup-commands'>
                      {
                        commandsList.map((com, i) =>
                          <button key={i} onClick={() =>
                            showConfPopup(com.value, 'status', com.multiselect, com.unit)}
                          >{com.label}</button>
                        )
                      }
                      {
                        props.popupProps.popupType === 'unit' &&
                  <button
                    onClick={()=>setLotoPopupIsOpen(true)}
                  >
                    Controls LOTO
                  </button>
                      }
                    </div>
                  </div>
                  : <></>
              }
            </>
        }
      </div>

      {
        lotoConfirm &&
        <div className='cover-conteiner cover-container-confirm'>
          <Draggable bounds='body' disabled={props.overInput}>
            <div className='control-popup-container' style={{padding: '20px 30px'}}>
              <div className='popup-row centre'>
                Unit locked out by {props.user.username}
              </div>
              <div className='popup-row commands-popup-control'>
                <button disabled={!lotoReason} className='bold'
                  onClick={() => confirmLOTO()}
                >
                  OK
                </button>
                <button className='bold'
                  onClick={() => {
                    setLotoConfirm(false);
                    setLotoReason('');
                  }}
                >CANCEL</button>
              </div>
            </div>
          </Draggable>
        </div>
      }
      {
        lotoReleaseConfirm &&
        <div className='cover-conteiner cover-container-confirm'>
          <Draggable bounds='body' disabled={props.overInput}>
            <div className='control-popup-container' style={{padding: '20px 30px'}}>
              <div className='popup-row centre'>
                Controls LOTO released by {props.user.username}
              </div>
              <div className='popup-row commands-popup-control'>
                <button className='bold'
                  onClick={() => confirmLOTO(true)}
                >
                  OK
                </button>
                <button className='bold'
                  onClick={() => {
                    setLotoReleaseConfirm(false);
                    setLotoReleaseNotes('');
                  }}
                >CANCEL</button>
              </div>
            </div>
          </Draggable>
        </div>
      }
      {
        lotoPopupIsOpen &&
        <div className='cover-conteiner cover-container-confirm'>
          <Draggable bounds='body' disabled={props.overInput}>
            <div className='control-popup-container' style={{padding: '20px 30px'}}>
              <div>
                <div className='loto-reason-row'>
                  <span>Reason for LOTO</span>
                  <Input
                    type='text'
                    onMouseEnter={() => props.setOverInput(true)}
                    onMouseLeave={() => props.setOverInput(false)}
                    value={lotoReason}
                    onChange={(val)=>setLotoReason(val)}
                    placeholder={'Enter reason'}
                  />
                </div>
                <div className='popup-row commands-popup-control'>
                  <button disabled={!lotoReason} className='bold'
                    onClick={() => {
                      setLotoConfirm(true);
                      setLotoPopupIsOpen(false);
                    }}
                  >
                  LOCKOUT
                  </button>
                  <button className='bold'
                    onClick={() => {
                      setLotoReason('');
                      setLotoPopupIsOpen(false);
                    }}
                  >CANCEL</button>
                </div>
              </div>
            </div>
          </Draggable>
        </div>
      }
      {
        showConfirmationPopup &&
        <div className='cover-conteiner cover-container-confirm'>
          {
            confirmationPopupChecklist && !afterSelectConfirmDlg ?
              <Draggable bounds='body' disabled={props.overInput}>
                <div className='control-popup-container' style={{padding: '20px 30px'}}>
                  <div className='title-line' style={{textAlign:'center'}}>
                    {titleMultiselectDlg(commandName)}
                  </div>
                  { multiselectItems.length !== 0 ?
                    <div className='rack-choose-list'>
                      <div id='first-cell'>
                        <button
                          disabled={!itemsSelected.includes(false)}
                          onClick={() => {
                            setItemsSelected(itemsSelected =>
                              itemsSelected.map(el => el === 'disabled' ? el : true)
                            );
                          }}>Select All</button>
                        <button
                          disabled = {!itemsSelected.includes(true)}
                          onClick={() => {
                            setItemsSelected(itemsSelected =>
                              itemsSelected.map(el => el === 'disabled' ? el : false)
                            );
                          }}>Unselect All</button>
                      </div>
                      {
                        multiselectItems.map((r,i) =>
                          <div key={i} className={
                            `item ${itemsSelected[i] === 'disabled'?'disabled':''}`}
                          onClick={()=>{
                            const newArr = [...itemsSelected];
                            newArr[i] = !itemsSelected[i];
                            setItemsSelected(newArr);
                          }}
                          >
                            <div className={`input-checkbox ${getCheckboxState(i)}`} />
                            <div>
                              {`${props.popupProps.popupType === 'site'? 'Unit':'Rack'} ${r}`}
                            </div>
                          </div>
                        )
                      }
                    </div> :
                    <div style={{textAlign: 'center', marginTop: '10px'}}>
                      {`No ${props.popupProps.popupType === 'site'?'units':'racks'} online`}
                    </div>
                  }
                  {
                    commands[0] === UNITS_CMD &&
                    <div className='popup-row' style={{alignSelf: 'center'}}>
                      <div className='bold'>Power</div>
                      <div className='popup-table'>
                        <div className='popup-table-col'>
                          <div>Real (P):</div>
                          <div className='range-lable' />
                          <div>Reactive (Q):</div>
                          <div className='range-lable' />
                        </div>
                        <div className='popup-table-col'>
                          <div>
                            <input
                              onKeyDown={(e) => {
                                if (e.keyCode === 13) {
                                  onEnter(2);
                                  e.preventDefault();
                                }
                              }}
                              className={'comand-popup-inp '}
                              type='number' step='0.1' value={unitReal}
                              onMouseEnter={() => props.setOverInput(true)}
                              onMouseLeave={() => props.setOverInput(false)}
                              onChange={e => setUnitReal(e.target.value)}
                            />
                          </div>
                          <div className='range-lable' />
                          <div>
                            <input ref={inputThirdRef}
                              className={'comand-popup-inp '}
                              type='number' step='0.1' value={unitReactive}
                              onMouseEnter={() => props.setOverInput(true)}
                              onMouseLeave={() => props.setOverInput(false)}
                              onChange={e => setUnitReactive(e.target.value)}
                            />
                          </div>
                          <div className='range-lable' />
                        </div>
                        <div className='popup-table-col'>
                          <div>kW</div>
                          <div className='range-lable' />
                          <div>kvar</div>
                          <div className='range-lable' />
                        </div>
                      </div>
                    </div>
                  }
                  <div className='popup-row commands-popup-control'>
                    <button className='bold' disabled={!itemsSelected.includes(true)}
                      onClick={() => {
                        submitPopup();
                        setAfterSelectConfirmDlg(true);
                      }}>OK</button>
                    <button className='bold'
                      onClick={() => {
                        setShowConfirmationPopup(false);
                        setCommands([]);
                        setCommandsUnit(undefined);
                        setMultiselectItems([]);
                        setItemsSelected([]);
                      }}
                    >CANCEL</button>
                  </div>
                </div>
              </Draggable>
              :
              <Draggable bounds='body' disabled={props.overInput}>
                <div className='control-popup-container small-popup'>
                  <div className='popup-row centre'>
                    {
                      <>
                        <div className='bold '>Are you sure to execute?</div>
                        <div className='normal'>
                          {commandName ? commandName : commands[0]}
                          {`${afterSelectConfirmDlg ? (rackConfirmStr()) : ''}`}
                        </div>
                      </>
                    }
                  </div>
                  <div className='popup-row commands-popup-control'>
                    {
                      <button className='bold'
                        onClick={() => {
                          submitPopup();
                          setShowConfirmationPopup(false);
                        }}
                      >OK</button>
                    }
                    <button className='bold' onClick={() => {
                      setShowConfirmationPopup(false);
                      setCommands([]);
                      setCommandsUnit(undefined);
                      setAfterSelectConfirmDlg(false);
                      setMultiselectItems([]);
                      setItemsSelected([]);
                    }}>CANCEL</button>
                  </div>
                </div>
              </Draggable>
          }
        </div>
      }
    </>
  );
};

export default storeConnector(CommandsSection, {
  service: ['popupProps'],
  devices: ['UnitTable', 'batteryTable','lotoTable'],
  site: ['siteMeta'],
  user: ['user','timeMode'],
});
