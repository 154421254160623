import React from 'react';
import 'components/elements/Notifications/Notifications.scoped.scss';
import { storeConnector } from 'store';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';

library.add(fas);

const Notifications = (props) => {
  return (
    props.tmpNotifications &&
      <div className='notifications-list' style={{pointerEvents:'none'}}>
        {
          props.tmpNotifications.map((n, i) =>
            <div key={n.id} className={`notification ${n.type} ${n.className}`}
              style={{pointerEvents:'all'}}
            >
              <div>
                <div>{n.message}</div>
                <div className='close-notification'
                  onClick={() => props.actions.closeNotification(n.id)}
                >&times;</div>
              </div>
              {
                n.yesCallBack &&
                <div>
                  <button onClick={() => {
                    props.actions.closeNotification(n.id);
                    n.yesCallBack();
                  }}>
                    <FontAwesomeIcon icon='check' />
                  </button>
                  <button onClick={() => {
                    props.actions.closeNotification(n.id);
                    n.noCallBack();
                  }}>
                    <FontAwesomeIcon icon='times' />
                  </button>
                </div>
              }
            </div>
          )
        }
      </div>
  );
};

export default storeConnector(Notifications, {
  service: ['tmpNotifications'],
});