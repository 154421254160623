import React from 'react';
import { storeConnector } from 'store';
import 'components/Battery/OperationsAndMonitoring/SiteUnitComandsPopup/SiteUnitComandsPopup.scoped.scss';

const AdvancedUnitCommandsSection = ({
  showExecuteCommandsPopup,

  popupProps,
  currentUnit,
  siteMeta,
}) => {
  const commands = siteMeta.Unit_Commands_Adv ?? [];

  const handleClickCommand = (command) => {
    showExecuteCommandsPopup([
      {
        command: command.Command,
        sn: popupProps.obj.SN,
        unitLabel: currentUnit?.UnitName,
      }
    ]);
  };

  return (
    <div className='commands-popup-content'>
      <div className='popup-content-col' />
      <div className='popup-content-col comands popup-row'>
        <div className='bold'>Commands</div>
        {commands.map(command => (
          <button
            key={`unit-advanced-command-button-${command.Command}`}
            onClick={(e) => {
              e.stopPropagation();
              handleClickCommand(command);
            }}
          >
            {command.Name}
          </button>
        ))}
      </div>
    </div>
  );
};

export default storeConnector(AdvancedUnitCommandsSection, {
  service: ['popupProps'],
  devices: ['currentUnit'],
  site: ['siteMeta'],
});
