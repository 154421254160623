const initState = {
  savingEvent: false,
  calendarEvents: [],
  eventsFromLog: [],
  eventsFromDB: [],
  selectedEventID: false,
  selectedEventDate: false,
  stackList: {},
};

export default (state = initState, action) => {
  const handlers = {
    'SET_EVENTS_FROM_DB': () => {
      return {...state, eventsFromDB: action.events};
    },
    'SET_EVENTS_FROM_LOG': () => {
      return {...state, eventsFromLog: action.events};
    },
    'SET_STACK_FROM_DB': () => {
      return {...state, stackList: action.stacks};
    },
    'SAVE_EVENT': () => {
      const db = [...state.eventsFromDB];
      if(action.index > -1){
        db[action.index] = action.event;
      }else{
        db.push(action.event);
      }
      console.log('SAVE_EVENT',[...state.eventsFromDB],[...db],action);
      return {...state, savingEvent: true, eventsFromDB: db};
    },
    'SAVE_EVENT_COMPLETE': () => {
      return {...state, savingEvent: false};
    },
    'DELETE_EVENT': () => {
      const db = [...state.eventsFromDB];
      db.splice(action.index,1);
      return {...state, eventsFromDB: db};
    },
    'UPDATE_IOT_EVENT': () => {
      let db = [...state.eventsFromDB];
      const event = action.event;
      db = db.filter((e)=> !!e.ID);
      const index = db.findIndex((e)=> +e.ID === +event.ID);
      if(event.status === 'removed'){
        if(index>-1){
          db.splice(index,1);
        }
      }else{
        if(index>-1){
          db[index] = event;
        }else{
          db.push(event);
        }
      }
      return {...state, eventsFromDB:db};
    },
    'SELECT_EVENT': () => {
      console.log('selectedEventID',action.id);
      return {...state, selectedEventID: action.id};
    },
    'SELECT_EVENT_DATE': () => {
      return {...state, selectedEventDate: action.date};
    },
    'DESELECT_EVENT': () => {
      return {...state, selectedEventID: false};
    },
    'UPDATE_CALENDAR_MODULE': () => {
      delete action.type;
      return { ...state, ...action };
    },
  };
  if (!handlers[action.type])
    return state;
  return handlers[action.type]();
};