import { callRESTThrottled, log } from 'utils';
import slsConfig from 'sls-stack-output.json';
import * as d3 from 'd3';
import * as AmazonCognitoIdentity from 'amazon-cognito-identity-js';
import { fromCognitoIdentityPool } from '@aws-sdk/credential-providers';
import { userPool } from 'store/userPool';
import { dispatcher, store } from 'store';
import { createIotClient } from 'store/IotClientCreator';
import { otherActions } from 'store/actionCreators/otherActions';
import { site } from 'store/actionCreators/site';
import { notifyError } from 'store/actionCreators/notifications';
import moment from 'moment';
import { CUSTOM_PAGE_URL } from 'components/CustomPages/constant';
const DEFAULT_INACTIVITY_LOGOUT_PERIOD = 2 * 60 * 60; //s
const DEFAULT_ANYACTIVITY_LOGOUT_PERIOD = 12 * 60 * 60; //s
const CHECK_AUTOLOGOUT_INTERVAL = 1000 * 60;// ms
const sitePages = ['home', 'battery', 'sun', 'mkt', 'wind', 'history', CUSTOM_PAGE_URL];

export const authorization = {
  updateClient(data) {
    return function (dispatch) {
      dispatch(dispatcher('UPDATE_USER_MODULE', { client: data }));
    };
  },

  getCredentials(session, hideLoading, fromLogin) {
    return function (dispatch) {
      return new Promise((resolve, reject) => {
        !hideLoading && dispatch(dispatcher('LOADING', { getAWSCredentials: true }));
        const token = session.getIdToken().jwtToken;
        let providerKey =
          `cognito-idp.${slsConfig.Region}.amazonaws.com/${slsConfig.CognitoUserPoolId}`;

        log('Getting new AWS credentials by AWS.config.credentials.get(...) ');
        fromCognitoIdentityPool({
          identityPoolId: slsConfig.CognitoIdentityPoolId,
          logins: {
            [providerKey]: token,
          },
          clientConfig: { region: slsConfig.Region },
        })().then((credentials, error) => {
          if (!credentials.accessKeyId) {
            log('accessKeyId after get credentials is undefined');
            reject('accessKeyId after get credentials is undefined');
          }
          log('Credentials received successfuly, calling attachPolicy by custom lambda');
          dispatch(dispatcher('UPDATE_USER_MODULE', {
            awsCredentials: credentials,
            awsRegion: slsConfig.Region
          }));
          dispatch(authorization.getMfaState()).then( async(mfaConfigured) => {
            if (mfaConfigured) {
              callRESTThrottled('POST', '/config/serve', {
                action: 'attachPolicy',
                policy: slsConfig.IoTPolicy
              }).then(async (data) => {
                log(
                  `Policy attached successfuly, response: ${JSON.stringify(data)}`
                );
                session && dispatch(dispatcher('LOGIN_SUCCESS', {
                  userdata: session,
                  fromLogin,
                }));
                const splitedPath = window.location.pathname.split('/');
                if (sitePages.includes(splitedPath[1])) {
                  await dispatch(site.getSiteMeta(splitedPath[2]));
                }
                createIotClient().then(() => {
                  !hideLoading && dispatch(dispatcher('LOADING', { getAWSCredentials: false }));
                  resolve();
                });
                !hideLoading && dispatch(dispatcher('LOADING', { getAWSCredentials: false }));
                resolve();
              });
            } else {
              const splitedPath = window.location.pathname.split('/');
              if (sitePages.includes(splitedPath[1])) {
                await dispatch(site.getSiteMeta(splitedPath[2]));
              }
              createIotClient().then(() => {
                session && dispatch(dispatcher('LOGIN_SUCCESS', {
                  userdata: session,
                  fromLogin,
                }));
                !hideLoading && dispatch(dispatcher('LOADING', { getAWSCredentials: false }));
                resolve();
              });

            }
          });
        }).catch(error => {
          !hideLoading && dispatch(dispatcher('LOADING', { getAWSCredentials: false }));
          log('Can\'t get AWS.config.credentials ', error);
          dispatch(dispatcher('UPDATE_MESSAGES_MODULE', { connectionAlert: true }));

          reject(error);
        });
        // AWS.config.credentials.get((error) => {
        //   if (error) {
        //     !hideLoading && dispatch(dispatcher('LOADING', { getAWSCredentials: false }));
        //     log('Can\'t get AWS.config.credentials ', error);
        //     store.dispatch(dispatcher('UPDATE_MESSAGES_MODULE', { connectionAlert: true }));

        //     reject(error);
        //     return;
        //   }
        //   if (!AWS.config.credentials.accessKeyId) {
        //     log('accessKeyId after get credentials is undefined');
        //     reject('accessKeyId after get credentials is undefined');
        //   }
        //   log('Credentials received successfuly, calling attachPolicy by custom lambda');
        //   dispatch(dispatcher('UPDATE_USER_MODULE', { awsCredentials: AWS.config.credentials }));
        //   dispatch(authorization.getMfaState()).then((mfaConfigured) => {
        //     if (mfaConfigured) {
        //       callRESTThrottled('POST', '/config/serve', {
        //         action: 'attachPolicy',
        //         policy: slsConfig.IoTPolicy
        //       }).then((data) => {
        //         log(
        //           `Policy attached successfuly, response: ${JSON.stringify(data)}`
        //         );
        //         session && dispatch(dispatcher('LOGIN_SUCCESS', {
        //           userdata: session,
        //           fromLogin,
        //         }));
        //         createIotClient().then(() => {
        //           !hideLoading && dispatch(dispatcher('LOADING', { getAWSCredentials: false }));
        //           resolve();
        //         });
        //         !hideLoading && dispatch(dispatcher('LOADING', { getAWSCredentials: false }));
        //         resolve();
        //       });
        //     } else {
        //       createIotClient().then(() => {
        //         session && dispatch(dispatcher('LOGIN_SUCCESS', {
        //           userdata: session,
        //           fromLogin,
        //         }));
        //         !hideLoading && dispatch(dispatcher('LOADING', { getAWSCredentials: false }));
        //         resolve();
        //       });

        //     }
        //   });
        // });
      });
    };
  },
  initUser(hideLoading) {
    return function (dispatch) {
      return new Promise((resolve, reject) => {
        const state = store.getState();
        if (state.user.cognitoUser != null) {
          !hideLoading && dispatch(dispatcher('LOADING', { initUser: true }));
          log('Getting session');
          state.user.cognitoUser.getSession((err, session) => {
            !hideLoading && dispatch(dispatcher('LOADING', { initUser: false }));
            if (err || !session) {
              log('Can\'t get session: ', err, ' session:', session);
              dispatch(dispatcher('USER_LOADED'));
              if (err && err.code === 'NetworkError') {
                reject();
              } else {
                window.location.href = '/login';
              }
            } else if (session.isValid) {
              log('session is valid, getting credentials');
              if (!localStorage.getItem('ANYACTIVITY_LOGOUT_DATE_PREV')) {
                store.getState().user.cognitoUser.signOut();
                dispatch(dispatcher('LOGOUT'));
                // AWS.config.credentials && AWS.config.credentials.clearCachedId();
                window.location.assign('/login');
                resolve();
              } else {
                dispatch(authorization.getCredentials(session, hideLoading))
                  .then(() => {
                    resolve();
                    dispatch(authorization.autoLogout(false, true));
                  }).catch((err) => {
                    reject(err);
                  });
              }
            } else {
              // session expired
              if (state.service.currentPath !== '/passwordReset' &&
                state.service.currentPath !== '/login') {
                window.location.href = '/login';
              }
              dispatch(dispatcher('USER_LOADED'));
              resolve();
            }
          });
        } else {
          // attempt first time without user in localstorage access to nonlogin page
          if (state.service.currentPath !== '/passwordReset' &&
            state.service.currentPath !== '/login') {
            window.location.href = '/login';
          }
          dispatch(dispatcher('USER_LOADED'));
          resolve();
        }
      });
    };
  },

  getMfaState() {
    return function (dispatch) {
      return new Promise((resolve, reject) => {
        store.getState().user.cognitoUser.getUserData((error, options) => {
          if (error) {
            dispatch(notifyError(error.message));
            reject();
            return;
          }
          store.getState().user.cognitoUser.clearCachedUserData();
          const mfaRequired = options.UserAttributes
            .find(a => a.Name === 'custom:mfa_required') ?
            options.UserAttributes.find(a =>
              a.Name === 'custom:mfa_required'
            ).Value === 'true' : true;
          const mfaEnabled = !!options.PreferredMfaSetting;

          const mfaConfigured = !mfaRequired || (mfaRequired && mfaEnabled);

          dispatch(dispatcher('UPDATE_USER_MODULE', { mfaConfigured }));
          resolve(mfaConfigured);
        });
      });
    };
  },

  logout() {
    return function (dispatch) {
      d3.select('#cubism-chart').remove();
      localStorage.removeItem(`EMS_${store.getState().user.user.username}_activeTzMode`);
      store.getState().user.cognitoUser.signOut();
      dispatch(dispatcher('LOGOUT'));
      // AWS.config.credentials.clearCachedId();
      window.location.assign('/login');
      localStorage.removeItem('INACTIVITY_LOGOUT_DATE_PREV');
      localStorage.removeItem('ANYACTIVITY_LOGOUT_DATE_PREV');
    };
  },

  authenticate(data, callbacks) {
    return function (dispatch) {
      dispatch(dispatcher('LOADING', { login: true }));
      dispatch(dispatcher('UPDATE_USER_MODULE', { loginError: '' }));
      store.getState().user.cognitoUser = new AmazonCognitoIdentity.CognitoUser({
        Username: data.Username,
        Pool: userPool,
      });
      var authenticationDetails = new AmazonCognitoIdentity.AuthenticationDetails({
        Username: data.Username,
        Password: data.Password,
      });
      store.getState().user.cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess(session) {
          dispatch(authorization.getCredentials(session, false, true)).then(() => {
            store.getState().service.history
              .push(store.getState().user?.user?.group === 'Administrator' ? '/admin' : '/');
            dispatch(dispatcher('LOADING', { login: false }));
            dispatch(authorization.autoLogout(false, true));
          });
        },
        onFailure: (err) => {
          dispatch(dispatcher('LOADING', { login: false }));
          dispatch(dispatcher('LOGIN_ERROR', { err }));
        },
        newPasswordRequired: () => {
          setTimeout(() => dispatch(dispatcher('LOADING', { login: false })), 1000);
          setTimeout(() => store.getState().service.history.push('/passwordReset'), 2000);
        },
        mfaSetup: (...args) => {
          setTimeout(() => dispatch(dispatcher('LOADING', { login: false })), 1000);
          setTimeout(() => store.getState().service.history.push('/mfaSetup'), 2000);
        },
        totpRequired: (...args) => {
          setTimeout(() => dispatch(dispatcher('LOADING', { login: false })), 1000);
          setTimeout(() => store.getState().service.history.push('/totpRequired'), 2500);
        }
      });
    };
  },
  autoLogout(isInit, firstCall) {
    return function (dispatch) {
      if (window.timerAutologout) {
        clearTimeout(window.timerAutologout);
      }
      let inactPrevDate = localStorage.getItem('INACTIVITY_LOGOUT_DATE_PREV');
      let anyactPrevDate = localStorage.getItem('ANYACTIVITY_LOGOUT_DATE_PREV');
      const currDate = moment().unix();
      if (!inactPrevDate || !anyactPrevDate) {
        localStorage.setItem('INACTIVITY_LOGOUT_DATE_PREV', currDate);
        inactPrevDate = currDate;
        localStorage.setItem('ANYACTIVITY_LOGOUT_DATE_PREV', currDate);
        anyactPrevDate = currDate;
      }
      dispatch(otherActions.getOptions()).then(res => {
        const logoutPeriods = {};
        if (res && res.result && res.result[0] && res.result[0].options) {
          const { inactInterval, anyactInterval } = res.result[0].options;
          logoutPeriods.inactInterval = inactInterval || DEFAULT_INACTIVITY_LOGOUT_PERIOD;
          logoutPeriods.anyactInterval =
            anyactInterval || DEFAULT_ANYACTIVITY_LOGOUT_PERIOD;
        } else {
          logoutPeriods.inactInterval = DEFAULT_INACTIVITY_LOGOUT_PERIOD;
          logoutPeriods.anyactInterval = DEFAULT_ANYACTIVITY_LOGOUT_PERIOD;
        }
        if (firstCall) {
          const currDateMomentInit = moment().unix();
          const inactDiffInit = currDateMomentInit - +inactPrevDate;
          const anyactDiffInit = currDateMomentInit - +anyactPrevDate;
          if (inactDiffInit > logoutPeriods.inactInterval ||
            anyactDiffInit > logoutPeriods.anyactInterval
          ) {
            if (store.getState().user.user.username) {
              dispatch(authorization.logout());
            }
          }
        }
        window.timerAutologout = window.setTimeout(() => {
          const currDateMoment = moment().unix();
          const inactDiff = currDateMoment - +inactPrevDate;
          const anyactDiff = currDateMoment - +anyactPrevDate;
          if (inactDiff > logoutPeriods.inactInterval ||
            anyactDiff > logoutPeriods.anyactInterval
          ) {
            if (store.getState().user.user.username) {
              dispatch(authorization.logout());
            }
          } else {
            dispatch(authorization.autoLogout(false));
          }
        }, CHECK_AUTOLOGOUT_INTERVAL);
      });
    };
  },

  completeNewPasswordChallenge(data) {
    return function (dispatch) {
      dispatch(dispatcher('LOADING', { compleatePassword: true }));
      dispatch(dispatcher('UPDATE_USER_MODULE', { loginError: '' }));
      store.getState().user.cognitoUser.completeNewPasswordChallenge(data.Password, {}, {
        onSuccess: (session) => {
          dispatch(dispatcher('LOADING', { compleatePassword: false }));
          dispatch(authorization.getCredentials(session)).then(() => {
            store.getState().service.history.push('/');
          });

        },
        onFailure: (err) => {
          dispatch(dispatcher('LOADING', { compleatePassword: false }));
          dispatch(dispatcher('LOGIN_ERROR', { err }));
        },
      });
    };
  },
  changePass(data) {
    return function (dispatch) {
      return new Promise((resolve, reject) => {
        store.getState().user.cognitoUser
          .changePassword(data.oldPass, data.newPass, function (err, result) {
            if (err) {
              reject(err);
            } else {
              resolve();
            }
          });
      });
    };
  },
  sendResetPassRequest(data) {
    return function (dispatch) {
      return new Promise((resolve, reject) => {
        const cognitoUser = new AmazonCognitoIdentity.CognitoUser({
          Username: data.Username,
          Pool: userPool,
        });
        cognitoUser.forgotPassword({
          onSuccess: (result) => {
            resolve();
          },
          onFailure: (err) => {
            reject(err);
            return;
          }
        });
      });
    };
  },
  getUsernameByEmail(data) {
    return function (dispatch) {
      return new Promise((resolve, reject) => {
        callRESTThrottled('POST', 'user/getUsernameByEmail', {
          email: data,
        }, false).then(r => resolve(r)).catch(r => reject(r));
      });
    };
  },
  confirmPassReset(data) {
    return function (dispatch) {
      return new Promise((resolve, reject) => {
        const cognitoUser = new AmazonCognitoIdentity.CognitoUser({
          Username: data.username,
          Pool: userPool,
        });
        cognitoUser.confirmPassword(data.code, data.newPassword, {
          onSuccess: (result) => {
            resolve();
          },
          onFailure: (err) => {
            reject(err);
            return;
          }
        });
      });
    };
  },
  setMfaConfigured(mfaConfigured) {
    return function (dispatch) {
      dispatch(dispatcher('UPDATE_USER_MODULE', { mfaConfigured }));
    };
  }
};