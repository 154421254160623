import { combineReducers } from 'redux';
import service from 'store/reducers/serviceReducer';
import user from 'store/reducers/userReducer';
import vpp from 'store/reducers/vppReducer';
import devices from 'store/reducers/devicesReducer';
import site from 'store/reducers/siteReducer';
import messages from 'store/reducers/messagesReducer';
import calendar from 'store/reducers/calendarReducer';
import notes from 'store/reducers/notesReducer';

export default combineReducers({
  service,
  user,
  vpp,
  devices,
  site,
  messages,
  calendar,
  notes,
});