import { callRESTThrottled } from 'utils';
import { dispatcher } from 'store';

export const pcsAndRack = {
  getPCS(unit) {
    return function (dispatch) {
      return new Promise((resolve) => {
        dispatch(dispatcher('UPDATE_DEVICES_MODULE', { currentPCS: null }));
        console.log('getPCS:', unit);
        if (unit && unit.SN) {
          callRESTThrottled('POST', '/config/serve', {
            action: 'getTableItems',
            table: 'PCS',
            extraParams: {
              'ScanIndexForward': false,
              'ExpressionAttributeValues': {
                ':v': unit.SN,
              },
              'Limit': 1,
              'KeyConditionExpression': 'SN = :v',
            },
          }).then((data) => {

            dispatch(dispatcher('UPDATE_DEVICES_MODULE', { currentPCS: data.result[0] || {} }));
            resolve();
          });
        }
        else {
          resolve();
        }
      });
    };
  },

  getRack(unit, getList) {
    return function (dispatch) {
      return new Promise((resolve) => {
        if (!getList) {
          dispatch(dispatcher('UPDATE_RACK', { rack: null }));
        }
        if (unit && unit.SN) {
          callRESTThrottled('POST', '/config/serve', {
            action: 'getTableItems',
            table: 'Rack',
            extraParams: {
              'ScanIndexForward': false,
              'ExpressionAttributeValues': {
                ':v': unit.SN,
              },
              'Limit': 1,
              'KeyConditionExpression': 'SN = :v',
            },
          }).then((data) => {
            if (getList) {
              resolve(data.result[0]);
            } else {
              dispatch(dispatcher('UPDATE_RACK', { rack: data.result[0] || {} }));
              resolve();
            }
          });
        }
        else {
          resolve();
        }
      });
    };
  },
};