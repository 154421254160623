import { callRESTThrottled } from 'utils';

export const admin = {
  adminGetAllUsers(pgToken){
    return function(dispatch){
      return new Promise((resolve, reject) => {
        callRESTThrottled('POST', '/admin/users', {
          action: 'getAllUsers',
          ...(pgToken ? {pgToken} : {}),
        }).then((r) => {
          resolve(r);
        });
      });
    };
  },
  adminCreateUser(user){
    return function(dispatch){
      return new Promise((resolve, reject) => {
        callRESTThrottled('POST', '/admin/users', {
          action: 'createUser',
          user: user,
        }).then((r) => {
          resolve(r);
        });
      });
    };
  },
  adminCreateUsers(users){
    return function(dispatch){
      return new Promise((resolve, reject) => {
        Promise.all(
          users.map((user)=>{
            return callRESTThrottled('POST', '/admin/users', {
              action: 'createUser',
              user: user,
            });
          })
        ).then((r)=>{
          resolve(r);
        });
      });
    };
  },
  adminDeleteUser(user){
    return function(dispatch){
      return new Promise((resolve, reject) => {
        callRESTThrottled('POST', '/admin/users', {
          action: 'deleteUser',
          user: user,
        }).then((r) => {
          resolve(r);
        });
      });
    };
  },
  adminUpdateUser(user){
    return function(dispatch){
      return new Promise((resolve, reject) => {
        callRESTThrottled('POST', '/admin/users', {
          action: 'updateUser',
          user: user,
        }).then((r) => {
          resolve(r);
        });
      });
    };
  },
  adminTest(){
    return function(dispatch){
      return new Promise((resolve, reject) => {
        callRESTThrottled('POST', '/admin/users', {
          action: 'test',
        }).then((r) => {
          resolve(r);
        });
      });
    };
  },
  adminBulkUpdateUsers(usernames, form){
    return function(){
      return new Promise((resolve, reject) => {
        callRESTThrottled('POST', '/admin/users', {
          action: 'bulkUpdateUser',
          usernames,
          form
        }).then((r) => {
          resolve(r);
        });
      });
    };
  },
};