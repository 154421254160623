import { bindActionCreators, createStore, applyMiddleware } from 'redux';
import { connect } from 'react-redux';
import rootReducer from 'store/reducers';
import thunk from 'redux-thunk';

import {authorization} from 'store/actionCreators/authorization';
import {control} from 'store/actionCreators/control';
import {eventSubscribtion} from 'store/actionCreators/eventSubscribtion';
import {messagesAlertsWarnings} from 'store/actionCreators/messagesAlertsWarnings';
import {otherActions} from 'store/actionCreators/otherActions';
import {pcsAndRack} from 'store/actionCreators/pcsAndRack';
import {site} from 'store/actionCreators/site';
import {pv} from 'store/actionCreators/pv';
import {units} from 'store/actionCreators/units';
import {inverters} from 'store/actionCreators/inverters';
import {vpps} from 'store/actionCreators/vpps';
import {admin} from 'store/actionCreators/admin';
import {calendar} from 'store/actionCreators/calendar';
import {config} from 'store/actionCreators/config';
import {kinesis} from 'store/actionCreators/kinesis';
import * as notifications from 'store/actionCreators/notifications';
import { notes } from 'store/actionCreators/notes';

const store = createStore(
  rootReducer,
  applyMiddleware(thunk)
);
export {store};

export function dispatcher(type, payload) {
  return {
    type,
    ...payload
  };
}

const history = store.getState().service.history;
store.dispatch(dispatcher('SET_CURRENT_PATH'));
history.listen(() => {
  store.dispatch(dispatcher('SET_CURRENT_PATH'));
});

export const actionCreators = {...authorization, ...control, ...eventSubscribtion, ...calendar,
  ...config, ...messagesAlertsWarnings, ...otherActions, ...pcsAndRack, ...site, ...units,
  ...inverters, ...vpps, ...admin, ...notifications, ...pv, ...kinesis, ...notes};

function mapDispatchToProps(dispatch) {
  return {actions: bindActionCreators(actionCreators, dispatch)};
}

export const storeConnector = (comp, data) => {
  const mapStateToProps = (state, ownProps) => {
    let usedFields = Object.keys(data).reduce((accumulator, currentValue)=>{
      if(data[currentValue] !== 'all'){
        const partialState = data[currentValue].reduce((acc, curValue) => {
          acc[curValue] = state[currentValue][curValue];
          return acc;
        }, {});
        return {...accumulator, ...partialState};
      } else {
        return {...accumulator, ...state[currentValue]};
      }
    },{});
    return {...usedFields, ...ownProps};
  };
  return connect(mapStateToProps, mapDispatchToProps)(comp);
};