import { MAX_NOTES_COUNT } from 'store/actionCreators/notes';

const initState = {
  notesDict: {
  }
};

export default (state = initState, action) => {
  const handlers = {
    'UPDATE_NOTES': () => {
      return {
        ...state,
        notesDict: {
          ...state.notesDict,
          [`${action.device}-${action.sn}`]: action.notes,
        }
      };
    },
    'CREATE_NOTE': () => {
      const notes = [
        action.note,
        ...state.notesDict[`${action.device}-${action.sn}`],
      ];

      return {
        ...state,
        notesDict: {
          ...state.notesDict,
          [`${action.device}-${action.sn}`]: notes.slice(0, MAX_NOTES_COUNT - 1),
        }
      };
    },
    'CLEAR_NOTE': () => {
      return {
        ...state,
        notesDict: {
          ...state.notesDict,
          [`${action.device}-${action.sn}`]: [],
        }
      };
    },
  };
  if (!handlers[action.type])
    return state;

  return handlers[action.type]();
};