import React, { useMemo, useState } from 'react';
import { storeConnector } from 'store';
import 'components/Battery/OperationsAndMonitoring/SiteUnitComandsPopup/SiteUnitComandsPopup.scoped.scss';

const AdvancedSiteCommandsSection = ({
  showExecuteCommandsPopup,

  popupProps,
  UnitTable,
  siteMeta,
}) => {
  const [selectedCommand, setSelectedCommand] = useState(undefined);
  const [selectedUnitsSn, setSelectedUnitsSn] = useState([]);

  const commands = siteMeta.Site_Commands_Adv ?? [];

  const handleClickCommand = (command) => {
    if (command.Device === 'Site') {
      showExecuteCommandsPopup([
        {
          command: command.Command,
          sn: popupProps.obj.SN,
        }
      ]);
    } else if(command.Device === 'Unit') {
      setSelectedCommand(command);
    }
  };

  const isValidInput = useMemo(() => {
    return selectedUnitsSn.length > 0;
  }, [selectedUnitsSn]);

  const handleClickConfirm = () => {
    if (!isValidInput) {
      return;
    }

    const commandsToExecute = selectedUnitsSn.map(unitSn => ({
      command: selectedCommand.Command,
      sn: unitSn,
      unitLabel: UnitTable.find(unit => unit.SN === unitSn)?.UnitName,
    }));

    showExecuteCommandsPopup(commandsToExecute,
      () => {
        setSelectedUnitsSn([]);
        setSelectedCommand(null);
      },
    );
  };

  const handleClickSelectAll = () => {
    setSelectedUnitsSn([...UnitTable.map(unit => unit.SN)]);
  };

  const handleClickUnselectAll = () => {
    setSelectedUnitsSn([]);
  };

  const handleClickCheckBox = (unitSn) => {
    setSelectedUnitsSn(prevUnitsSn => prevUnitsSn.includes(unitSn) ?
      prevUnitsSn.filter(value => value !== unitSn) :
      [...prevUnitsSn, unitSn]
    );
  };

  return (
    <div className='commands-popup-content'>
      <div className='popup-content-col' />
      <div className='popup-content-col comands popup-row'>
        <div className='bold'>Commands</div>
        {commands.map(command => (
          <button
            key={`unit-advanced-command-button-${command.Command}-${command.Name}`}
            onClick={(e) => {
              e.stopPropagation();
              handleClickCommand(command);
            }}
          >
            {command.Name}
          </button>
        ))}
      </div>
      {selectedCommand && (
        <div className='cover-conteiner cover-container-confirm'>
          <div>
            <div className='control-popup-container' style={{padding: '20px 30px'}}>
              <div className='title-line' style={{textAlign:'center'}}>
                Select Converters to execute command: {selectedCommand.Command}.
              </div>
              {UnitTable.length > 0 ? (
                <div className='rack-choose-list'>
                  <div id='first-cell'>
                    <button
                      onClick={handleClickSelectAll}
                    >
                      Select All
                    </button>
                    <button onClick={handleClickUnselectAll}>
                      Unselect All
                    </button>
                  </div>
                  {UnitTable.map((unit) =>
                    <div
                      key={`unit-cheeckbox-${unit.SN}`}
                      className='item'
                      onClick={() => handleClickCheckBox(unit.SN)}
                    >
                      <div className={`input-checkbox ${selectedUnitsSn.includes(unit.SN) ? 'act' : ''}`}/>
                      <div>
                        {`${unit.UnitName}`}
                      </div>
                    </div>
                  )}
                </div>
              ) : (
                <div style={{textAlign: 'center', marginTop: '10px'}}>
                  {'No converters online'}
                </div>
              )}
              <div className='popup-row commands-popup-control'>
                <button
                  className='bold'
                  disabled={!isValidInput}
                  onClick={() => handleClickConfirm()}
                >
                  OK
                </button>
                <button className='bold'
                  onClick={() => {
                    setSelectedCommand(null);
                    setSelectedUnitsSn([]);
                  }}
                >
                  CANCEL
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default storeConnector(AdvancedSiteCommandsSection, {
  service: ['popupProps'],
  devices: ['UnitTable'],
  site: ['siteMeta'],
});
