import React, { useMemo, useState } from 'react';
import { storeConnector } from 'store';
import 'components/Battery/OperationsAndMonitoring/SiteUnitComandsPopup/SiteUnitComandsPopup.scoped.scss';
import ConverterCommandButtons from './ConverterCommandButtons';

const ValueCommandForm = ({
  formLabel,
  valueLabel,
  unitLabel,
  allowedMin,
  allowedMax,
  initialValue,
  command,
  unit,
  popupProps,
  showExecuteCommandsPopup,
  setOverInput,
}) => {
  const [value, setValue] = useState(initialValue);

  const isValidValue = useMemo(() =>
    (allowedMin === undefined || allowedMin <= +value) &&
    (allowedMax === undefined || allowedMax >= +value),
  [
    allowedMin,
    allowedMax,
    value,
  ]
  );

  const handleSubmitPowerCommand = () => {
    showExecuteCommandsPopup([
      {
        command: `${command}::${value}`,
        unit,
        sn: popupProps.obj.SN
      }
    ]);
  };

  return (
    <>
      <div className='popup-row'>
        <div className='bold vertical-margin'>
          {formLabel}
        </div>
        <div className='popup-table'>
          <div className='popup-table-col' style={{ minWidth: '70px' }}>
            <div>{valueLabel}</div>
            <div className='range-lable' />
          </div>
          <div className='popup-table-col'>
            <div>
              <input
                className={'comand-popup-inp '+
                  (!isValidValue ? 'error' : '')}
                type='number'
                step='0.1'
                value={value}
                onMouseEnter={() => setOverInput(true)}
                onMouseLeave={() => setOverInput(false)}
                onChange={e => setValue(e.target.value)}
              />
            </div>
            <div className='range-lable'>
              {allowedMin !== undefined && allowedMax !== undefined && (
                <>Range from {allowedMin} to {allowedMax}</>
              )}
              {allowedMin !== undefined && allowedMax === undefined && (
                <>Greater than or Equal to {allowedMin}</>
              )}
              {allowedMin === undefined && allowedMax !== undefined && (
                <>Less than or Equal to {allowedMax}</>
              )}
            </div>
          </div>
          <div className='popup-table-col'>
            <div>{unitLabel}</div>
            <div className='range-lable' />
          </div>
        </div>
      </div>
      {!isValidValue && (
        <div className='popup-row warn-alert'>
          {allowedMin !== undefined && allowedMax !== undefined && (
            <>The value must be between {allowedMin} and {allowedMax}</>
          )}
          {allowedMin !== undefined && allowedMax === undefined && (
            <>The value must be Greater than or Equal to {allowedMin}</>
          )}
          {allowedMin === undefined && allowedMax !== undefined && (
            <>The value must be Less than or Equal to {allowedMax}</>
          )}
        </div>
      )}
      <div className='popup-row commands-popup-control'>
        <button
          className='bold'
          disabled={!isValidValue}
          onClick={handleSubmitPowerCommand}
        >
          Send
        </button>
      </div>
    </>
  );
};

const ConverterCommandsSection = ({
  setOverInput,
  showExecuteCommandsPopup,

  popupProps,
  currentUnit,
}) => {
  return (
    <div className='commands-popup-content'>
      <div className='popup-content-col'>
        <ValueCommandForm
          formLabel='Power'
          valueLabel='Real (P):    '
          unitLabel='kW'
          allowedMin={currentUnit.BESS_MinP ? +currentUnit.BESS_MinP : -3}
          allowedMax={currentUnit.BESS_MaxP ? +currentUnit.BESS_MaxP : 10}
          initialValue={currentUnit.DCDC_PCS_P ? +currentUnit.DCDC_PCS_P : 0}
          command='Power'
          unit='DCDC'
          popupProps={popupProps}
          showExecuteCommandsPopup={showExecuteCommandsPopup}
          setOverInput={setOverInput}
        />
        <ValueCommandForm
          formLabel='ITC Buffer'
          valueLabel='ITC Buffer:'
          unitLabel='kW'
          allowedMin={0}
          initialValue={currentUnit.ITCBuffer ? +currentUnit.ITCBuffer : 0}
          command='SetITCBuffer'
          popupProps={popupProps}
          showExecuteCommandsPopup={showExecuteCommandsPopup}
          setOverInput={setOverInput}
        />
        <ValueCommandForm
          formLabel='Ramp Rate'
          valueLabel='Ramp Rate:'
          unitLabel='%/s'
          allowedMin={0}
          allowedMax={100}
          initialValue={currentUnit.DCDCRampRate ? +currentUnit.DCDCRampRate : 0}
          command='SetDCDCRampRate'
          popupProps={popupProps}
          showExecuteCommandsPopup={showExecuteCommandsPopup}
          setOverInput={setOverInput}
        />
      </div>
      <div className='popup-content-col comands popup-row'>
        <div className='bold'>Commands</div>
        <ConverterCommandButtons
          setOverInput={setOverInput}
          showExecuteCommandsPopup={showExecuteCommandsPopup}
        />
      </div>
    </div>
  );
};

export default storeConnector(ConverterCommandsSection, {
  service: ['popupProps'],
  devices: ['currentUnit'],
});
