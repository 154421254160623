import { callRESTThrottled } from 'utils';
import { dispatcher } from 'store';

const CLEARED_NOTE = 'Note cleared';
export const MAX_NOTES_COUNT = 3;

export const notes = {
  getNotes({sn, device}) {
    return function (dispatch) {
      return new Promise((resolve) => {
        callRESTThrottled('POST', '/config/serve', {
          action: 'getTableItems',
          table: 'Notes',
          extraParams: {
            'ScanIndexForward': false,
            'ExpressionAttributeValues': {
              ':sn': sn,
              ':device': device,
            },
            KeyConditionExpression: 'SN = :sn',
            FilterExpression: 'Device = :device',
            Limit: MAX_NOTES_COUNT,
          },
        }).then(({ result }) => {
          const clearedNoteIndex = result.findIndex(note => note.Content === CLEARED_NOTE);

          const activeNotes = clearedNoteIndex === -1 ? result : result.slice(0, clearedNoteIndex);

          dispatch(dispatcher('UPDATE_NOTES', { sn, device, notes: activeNotes }));

          resolve(activeNotes);
        });
      });
    };
  },
  createNote({device, sn, content}) {
    return function (dispatch) {
      return new Promise(async (resolve, reject) => {
        const { result: { note } } = await callRESTThrottled(
          'POST',
          '/config/v2/serve',
          {
            action: 'createNote',
            device,
            sn,
            content,
          }
        );

        dispatch(dispatcher('CREATE_NOTE', { sn, device, note }));

        return resolve();
      });
    };
  },
  clearNote({device, sn}) {
    return function (dispatch) {
      return new Promise(async (resolve, reject) => {
        await callRESTThrottled(
          'POST',
          '/config/v2/serve',
          {
            action: 'clearNote',
            device,
            sn,
          }
        );

        dispatch(dispatcher('CLEAR_NOTE', { sn, device }));

        return resolve();
      });
    };
  },
};
