import React from 'react';
import { storeConnector } from 'store';
import 'components/Battery/OperationsAndMonitoring/SiteUnitComandsPopup/SiteUnitComandsPopup.scoped.scss';
import { useMemo, useState } from 'react';

const SiteConverterPowerCommandForm = ({ currentSite, setOverInput, showConfPopup }) => {
  const [real, setReal] = useState(currentSite?.PCS_P_DC ?? 0);
  const allowedMinReal = useMemo(() =>
    currentSite?.BESS_MinP !== undefined ? +currentSite?.BESS_MinP : -3,
  [currentSite]
  );
  const allowedMaxReal = useMemo(() =>
    currentSite?.BESS_MaxP !== undefined ? +currentSite?.BESS_MaxP : 10,
  [currentSite]
  );
  const isValidReal = useMemo(() =>
    +real >= allowedMinReal && +real <= allowedMaxReal,
  [real, allowedMinReal, allowedMaxReal]
  );

  const onChangeReal = (e) => setReal(e.target.value);
  const handleSend = () => {
    if (!isValidReal) {
      return;
    }

    showConfPopup(`DCPower::${real}`, 'value');
  };

  return (
    <>
      <div className='popup-row'>
        <div className='bold vertical-margin'>
          BESS Power
        </div>
        <div className='popup-table'>
          <div className='popup-table-col' style={{width: 85}}>
            <div>Real (P):</div>
          </div>
          <div className='popup-table-col'>
            <div>
              <input
                onKeyDown={(e) => {
                  if (e.keyCode === 13) {
                    e.preventDefault();
                    handleSend();
                  }
                }}
                className={'comand-popup-inp ' + (!isValidReal? 'error':'')}
                type='number'
                step='0.1'
                value={real}
                onMouseEnter={() => setOverInput(true)}
                onMouseLeave={() => setOverInput(false)}
                onChange={onChangeReal}
              />
            </div>
            <div className='range-lable'>
              Range from {allowedMinReal} to {allowedMaxReal}
            </div>
          </div>
          <div className='popup-table-col'>
            <div>kW</div>
          </div>
        </div>
      </div>
      {!isValidReal && (
        <div className='popup-row warn-alert'>
          The value of real power must be between {allowedMinReal} and {allowedMaxReal}
        </div>
      )}
      <div className='popup-row commands-popup-control'>
        <button className='bold'
          disabled={!isValidReal}
          onClick={handleSend}
        >
          Send
        </button>
      </div>
    </>
  );
};

export default storeConnector(SiteConverterPowerCommandForm, {
  site: ['currentSite'],
});
