import React, { useEffect, useRef } from 'react';
import { storeConnector } from 'store';
import useSound from 'use-sound';

const sound = '/audio/unit-fault3.wav';

const FaultStatuses = [
  'Battery Fault',
  'PCS Fault',
  'BOP Fault',
  'No PCS Comms',
  'No Battery Comms',
  'Fault'
];

const getUnitsStatusMap = (units) => {
  return units.reduce((prev, cur) => ({
    ...prev,
    [cur.SN]: cur.InvStatus,
  }), {});
};

const usePrevious = (value) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

const isEmptyObject = (value) => {
  if (value === undefined || value === null) {
    return true;
  }
  for (const property in value) {
    return false;
  }

  return true;
};

const UnitsFaultAlarm = (props) => {
  const [playSound] = useSound(sound);

  const { UnitTable, unitFaultBannerAlarmEnabled, unitFaultAudioAlarmEnabled } = props;
  const prevProps = usePrevious({UnitTable});

  useEffect(() => {
    if (isEmptyObject(prevProps)
      || isEmptyObject(prevProps.UnitTable)
    ) {
      return;
    }
    const currentUnits = getUnitsStatusMap(UnitTable);
    const prevUnits = getUnitsStatusMap(prevProps.UnitTable);

    let hasUnitChangedToFault = false;

    Object.keys(prevUnits).forEach((sn) => {
      if (prevUnits[sn]
          && currentUnits[sn]
          && prevUnits[sn] !== currentUnits[sn]
          && prevUnits[sn] !== 'Connecting'
          && FaultStatuses.includes(currentUnits[sn])
      ) {
        const unitData = UnitTable.find((unit) => unit.SN === sn);

        hasUnitChangedToFault = true;
        if (unitFaultBannerAlarmEnabled) {
          props.actions.notifyError(
            `Unit ${unitData?.UnitName ?? sn} changed to ${currentUnits[sn]}`,
            0,
          );
        }
      }
    });

    if (hasUnitChangedToFault) {
      if (unitFaultAudioAlarmEnabled) {
        playSound();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prevProps, UnitTable, playSound]);

  return (
    <></>
  );
};

// export default storeConnector(UnitsFaultAlarm, { unit: ['units'], site: ['siteMeta'], user: ['user'] });
export default storeConnector(UnitsFaultAlarm, {
  devices: ['UnitTable'],
  site: ['siteMeta'],
  user: [
    'unitFaultAudioAlarmEnabled',
    'unitFaultBannerAlarmEnabled',
  ],
});