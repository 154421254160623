import React from 'react';
import Input from 'components/elements/Input/Input';
import { storeConnector } from 'store';

const UserAttribueCheckBoxLine = ({
  label,
  initialValue,
  value,
  setValue,
  inputLabel,
  attributeName,
  cognitoAttributeName,
  setLoading,
  actions,
}) => {
  const updateUserAttribute = async () => {
    setLoading(true);
    await actions.changeUserData([{
      Name: cognitoAttributeName,
      Value: (!initialValue).toString(),
    }]);
    setLoading(false);
    actions.updateStore({ user: {[attributeName]: !initialValue}});
  };

  return (<>
    <div>{label}</div>
    <div style={{zIndex: '1', display: 'flex', alignItems: 'center', opacity: '1'}}>
      <Input
        onChange={() => setValue((prevValue) => !prevValue)}
        checked={value}
        label={inputLabel}
        type='toggle'
        styleDD={{
          maxWidth: '60px',
          minWidth: '60px',
          marginRight: 'auto',
        }}
      />
      {
        value !== initialValue ?
          <>
            <button
              style={{width: '56px'}}
              className='type-small-light'
              onClick={() => updateUserAttribute()}
            >
            Save
            </button>
            <button
              style={{width: '56px'}}
              className='type-small-light'
              onClick={() => setValue(initialValue)}
            >
            Cancel
            </button>
          </>
          :
          <></>
      }
    </div>
  </>);
};

export default storeConnector(UserAttribueCheckBoxLine, {});