import React from 'react';
import { useTranslation } from 'react-i18next';

const SupportPopup = (props) => {
  const { t } = useTranslation();
  return (
    <div className='cover-conteiner'>
      <div className='control-popup-container'>
        <div className='title-profile'>{t('FRACTAL_OPERATIONS_CENTER')}</div>
        <div className='popup-close' onClick={()=>props.close()} />
        <div className='profile-content' style={{minWidth:'350px'}}>
          <div>{t('PHONE')}</div>
          <div>+1 (512) 646-9689</div>
          <div>{t('EMAIL')}</div>
          <div>operations@fractalems.com</div>
          <div>{t('HELP_PORTAL')}</div>
          <div className='opaque'>
            <a className='color-active' target='_blank' rel='noopener noreferrer'
              href='http://fractal-ems-fractal.happyfox.com/'
            >HappyFox</a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SupportPopup;
