var moment = require('moment-timezone');

var filterEventsByTimeFrame = function(baseEvents, timeFrame, tz){

  //timeFrame - format YYYY-MM-DD
  //tz - string format

  const eventWithDate = (event, date) => {
    const newEvent = JSON.parse(JSON.stringify(event));
    newEvent.startDate = date.format('MM/DD/YYYY');
    const startTimeArr = event.startTime.split(':');
    const stopTimeArr = event.stopTime.split(':');

    newEvent.start = date.hour(startTimeArr[0]).minute(startTimeArr[1]).seconds(startTimeArr[2]);
    newEvent.start.seconds(newEvent.start.seconds() + 1);
    newEvent.stop =
      date.clone().hour(stopTimeArr[0]).minute(stopTimeArr[1]).seconds(stopTimeArr[2]);
    if(newEvent.start > newEvent.stop){
      newEvent.stop.date(newEvent.stop.date() + 1);
    }
    return newEvent;
  };
  const getWeekOrder = (date) => {
    const currDate = moment(date).date();
    const lastDate = moment(date).daysInMonth();
    if (currDate + 7 > lastDate) {
      return 'last';
    }
    return Math.floor(currDate/7);
  };
  const monthDayByWeek = (dayOfWeek, weekOfMonth, date) => {
    const firstDate = date.clone().date(1);
    const firstDateDay = firstDate.day();
    if (weekOfMonth === 'last') {
      const lastDate = moment(date).daysInMonth();
      const lastDateMoment = moment(date).date(lastDate);
      while (lastDateMoment.day() !== dayOfWeek) {
        lastDateMoment.add(-1,'days');
      }
      return lastDateMoment.date();
    }
    if (firstDateDay <= dayOfWeek) {
      return 7 * weekOfMonth + dayOfWeek - firstDateDay + 1;
    }
    return 7 * weekOfMonth + dayOfWeek + firstDateDay + 1;

  };
  const getNthMonthWeekly = (startDate, tillDate, frequency) => {
    const dayOfWeek = startDate.day();
    const weekOfMonth = getWeekOrder(startDate);
    const curDate = startDate.clone();
    let ntimes = 0;
    while (!curDate.isAfter(tillDate)) {
      curDate.date(1);
      curDate.add(frequency, 'M');
      curDate.date(monthDayByWeek(dayOfWeek, weekOfMonth, curDate));
      ntimes = 1 + ntimes;
    }
    return ntimes - 1;
  };

  if(!timeFrame){ return []; }
  if(!baseEvents){ return []; }
  if(!Array.isArray(baseEvents)){ baseEvents = [baseEvents]; }
  if(typeof timeFrame === 'string'){ timeFrame = [timeFrame,timeFrame]; }
  const timeFrameStart = moment.tz(timeFrame[0],tz).hour(0).minute(0).seconds(0);
  const timeFrameEnd = moment.tz(timeFrame[1],tz).hour(0).minute(0).seconds(0);
  const timeFrameDiff = timeFrameEnd.diff(timeFrameStart,'days');

  const allEvents = [];

  baseEvents.forEach((event)=>{

    let {recurrence,period,repeatTime,startDate,tillDate,frequencyPeriod, monthlyType} = event;
    const activeWeekdays = event.activeWeekdays ? JSON.parse(event.activeWeekdays) : [];
    const startDateArr = startDate.split('/');
    const tillDateArr = tillDate && tillDate.split('/');

    const eventStart =
      moment.tz(`${startDateArr[2]}-${startDateArr[0]}-${startDateArr[1]}T00:00:00`,tz);

    tillDate = tillDate ?
      (moment.tz(`${tillDateArr[2]}-${tillDateArr[0]}-${tillDateArr[1]}T00:00:00`,tz)):eventStart;

    let eventStartWeek;

    for(let day = 0; day <= timeFrameDiff; day++){
      const frameDate = timeFrameStart.clone();
      frameDate.date(frameDate.date() + day);

      if(['forever','tilldate'].includes(recurrence)){
        const diffStart = frameDate.diff(eventStart,'day');
        const diffTillDate = recurrence === 'tilldate' && tillDate.diff(frameDate,'day');
        if(diffStart < 0){ continue; }
        if(recurrence === 'tilldate' && diffTillDate < 0){ break; }

        if(period === 'weekend' && frameDate.isoWeekday()>=6){
          allEvents.push(eventWithDate(event,frameDate));
        }
        if(period === 'weekday' && frameDate.isoWeekday()<6){
          allEvents.push(eventWithDate(event,frameDate));
        }
        if(period === 'day'){
          allEvents.push(eventWithDate(event,frameDate));
        }
        if(period === 'month' && eventStart.date() === frameDate.date()){
          allEvents.push(eventWithDate(event,frameDate));
        }
        if(period === 'ndays'){
          if (frequencyPeriod === 'd' && (diffStart % event.frequencyValue) === 0) {
            allEvents.push(eventWithDate(event,frameDate));
          }
          if (frequencyPeriod === 'm') {
            if (monthlyType ==='date') {
              const diffStartMonth = frameDate.diff(eventStart,'month');
              if ((diffStartMonth % event.frequencyValue) === 0 &&
                eventStart.date() === frameDate.date()
              ) {
                allEvents.push(eventWithDate(event,frameDate));
              }
            }
            if (monthlyType === 'weekday') {
              const diffStartMonthWeekly =
                ((frameDate.clone()).date(1)).diff((eventStart.clone().date(1)),'month');
              if ((diffStartMonthWeekly % event.frequencyValue) === 0 &&
                getWeekOrder(eventStart) === getWeekOrder(frameDate) &&
                eventStart.format('dddd') === frameDate.format('dddd')
              ) {
                allEvents.push(eventWithDate(event,frameDate));
              }
            }
          }
          if (frequencyPeriod === 'w') {
            const diffStartWeek = ((frameDate.clone()).day(1))
              .diff((eventStart.clone()).day(1),'week');
            if ((diffStartWeek % event.frequencyValue) === 0 &&
              (activeWeekdays[frameDate.weekday()] ||
                moment(eventStart).weekday() === frameDate.weekday())
            ) {
              allEvents.push(eventWithDate(event,frameDate));
            }
          }
        }
      } else if (['once','ntimes'].includes(recurrence)){
        if (['ndays'].includes(period)) {
          const diffStart = frameDate.diff(eventStart, 'day');
          if (diffStart < 0) {
            continue;
          }
          if (frequencyPeriod === 'd') {
            const nth = (diffStart / event.frequencyValue);
            const needRepeatTime = (recurrence === 'once') ? 0 : repeatTime;
            if (nth > needRepeatTime) {
              break;
            }
            if ((diffStart % event.frequencyValue) !== 0) {
              continue;
            }
            allEvents.push(eventWithDate(event,frameDate));
          }
          if (frequencyPeriod === 'm') {
            const diffStartMonthM = frameDate.diff(eventStart,'month');
            const diffStartMonthWeeklyM =
              ((frameDate.clone()).date(1)).diff((eventStart.clone().date(1)),'month');
            const nthMonth = (diffStartMonthM / event.frequencyValue);
            const nthMonthWeekly =
              frameDate.clone().date(1).diff(eventStart.clone().date(1)) === 0 ? 0 :
                getNthMonthWeekly(eventStart, frameDate, event.frequencyValue);
            const needRepeatTimeMonth = (recurrence === 'once') ? 0 : repeatTime;
            if (monthlyType ==='date' && nthMonth > needRepeatTimeMonth) {
              break;
            }
            if (monthlyType ==='date' && (diffStartMonthM % event.frequencyValue) !== 0) {
              continue;
            }
            if (monthlyType === 'weekday' && nthMonthWeekly > needRepeatTimeMonth) {
              break;
            }
            if (monthlyType === 'weekday' && (diffStartMonthWeeklyM % event.frequencyValue) !== 0) {
              continue;
            }
            if ((monthlyType ==='date' && eventStart.date() === frameDate.date())
            || (
              monthlyType ==='weekday'
              && getWeekOrder(eventStart) === getWeekOrder(frameDate)
              && eventStart.format('dddd') === frameDate.format('dddd')
              )
            ) {
              allEvents.push(eventWithDate(event,frameDate));
            }
          }
          if (frequencyPeriod === 'w') {
            const diffStartWeek =
              ((frameDate.clone()).day(1)).diff((eventStart.clone()).day(1),'week');
            const needRepeatTime = (recurrence === 'once') ? 0 : repeatTime;
            const nthWeek = (diffStartWeek / event.frequencyValue);
            if (nthWeek > needRepeatTime) {
              break;
            }
            if((diffStartWeek % event.frequencyValue) !== 0){
              continue;
            }
            if (activeWeekdays[frameDate.weekday()] ||
              moment(eventStart).weekday() === frameDate.weekday()
            ) {
              allEvents.push(eventWithDate(event,frameDate));
            }
          }
        } else {
          const diffStart = frameDate.diff(eventStart, period === 'month' ? 'month' : 'day');
          if(diffStart < 0){ continue; }
          const needRepeatTime = (recurrence === 'once')?0:repeatTime;

          if(['day','month'].includes(period)){
            if(diffStart > needRepeatTime){ break; }
            if(period === 'day'){
              allEvents.push(eventWithDate(event,frameDate));
            }else if(eventStart.date() === frameDate.date()){
              allEvents.push(eventWithDate(event,frameDate));
            }
          }else if(['weekend','weekday'].includes(period)){
            let weekdiff;
            const d = eventStart.isoWeekday();
            const isWeekend = period === 'weekend';

            if(isWeekend){
              weekdiff = 6 - d;
            }else{
              weekdiff = (d <= 5) ? (-d + 1) : (-d + 8);
            }
            if(!eventStartWeek){
              eventStartWeek = eventStart.clone().date(eventStart.date() + weekdiff);
            }
            const diff = frameDate.diff(eventStartWeek,'day');
            const weekSkiped = Math.floor(diff / 7);
            let day = diff % 7;
            let currentTime = weekSkiped*(isWeekend?2:5) + day + (weekdiff>0?0:weekdiff);

            if(day > (isWeekend?1:4) || currentTime < 0){ continue; }
            if(currentTime > needRepeatTime){ break; }
            allEvents.push(eventWithDate(event,frameDate));
          }
        }
      }
    }
  });

  return allEvents;
};

module.exports.filterEventsByTimeFrame = filterEventsByTimeFrame;