import React, { useMemo, useState } from 'react';
import { storeConnector } from 'store';
import 'components/Battery/OperationsAndMonitoring/SiteUnitComandsPopup/SiteUnitComandsPopup.scoped.scss';

const ConverterUnit = 'DCDC';
const BmsUnit = 'BMS';
const ConverterCommands = [
  {
    command: 'Power',
    unit: ConverterUnit,
    label: 'Converter Power Command',
  },
  {
    command: 'Start',
    unit: ConverterUnit,
    label: 'Start Converters',
  },
  {
    command: 'Stop',
    unit: ConverterUnit,
    label: 'Stop Converters',
  },
  {
    command: 'Reset',
    unit: ConverterUnit,
    label: 'Reset Converter',
  },
  {
    command: 'Start',
    unit: BmsUnit,
    label: 'Close All Contactors',
  },
  {
    command: 'Stop',
    unit: BmsUnit,
    label: 'Open All Contactors',
  },
  {
    command: 'Reset',
    unit: BmsUnit,
    label: 'Reset BMS',
  },
];

const ConverterCommandButtons = ({
  setOverInput,
  showExecuteCommandsPopup,

  siteMeta,
  popupProps,
}) => {
  const [selectedCommand, setSelectedCommand] = useState(null);
  const [selectedConverters, setSelectedConverters] = useState([]);
  const [real, setReal] = useState(0);

  const getConvertedIdxStr = (i) => {
    return String(i+1).padStart(String(siteMeta.NumConverters).length, '0');
  };

  const allowedMinReal = popupProps.obj.allowedMinReal ||
    +popupProps.obj.AllowedMinP || -3;
  const allowedMaxReal = popupProps.obj.allowedMaxReal ||
    +popupProps.obj.AllowedMaxP || 10;

  const convertersCount = useMemo(() => +siteMeta.NumConverters, [siteMeta.NumConverters]);
  const convertersArray = useMemo(() => Array(convertersCount).fill()
    .map((_, i) => getConvertedIdxStr(i)
    //eslint-disable-next-line react-hooks/exhaustive-deps
    ), [convertersCount]);
  const isValidReal = true;
  const isValidInput = useMemo(() => {
    if (selectedConverters.length === 0) {
      return false;
    }

    return isValidReal;
  }, [selectedConverters, isValidReal]);

  const handleClickConfirm = () => {
    if (!isValidInput) {
      return;
    }

    const commandsToExecute = selectedConverters.map(converterIndex => ({
      command: selectedCommand.command === 'Power' ?
        `Power::${real}` : selectedCommand.command,
      unit: `${selectedCommand.unit}${converterIndex}`,
      sn: popupProps.obj.SN
    }));

    showExecuteCommandsPopup(commandsToExecute,
      () => {
        setSelectedConverters([]);
        setSelectedCommand(null);
      },
    );
  };

  const handleClickSelectAll = () => {
    setSelectedConverters([...convertersArray]);
  };

  const handleClickUnselectAll = () => {
    setSelectedConverters([]);
  };

  const handleClickCheckBox = (converter) => {
    setSelectedConverters(prevConverters => prevConverters.includes(converter) ?
      prevConverters.filter(value => value !== converter) :
      [...prevConverters, converter]
    );
  };

  return (
    <>
      {ConverterCommands.map((converterCommand) => (
        <button
          key={`converter-command-button-${converterCommand.command}-${converterCommand.unit}`}
          onClick={(e) => {
            e.stopPropagation();
            setSelectedCommand(converterCommand);
          }}>
          {converterCommand.label}
        </button>
      ))}

      {selectedCommand && (
        <div className='cover-conteiner cover-container-confirm'>
          <div>
            <div className='control-popup-container' style={{padding: '20px 30px'}}>
              <div className='title-line' style={{textAlign:'center'}}>
                Select Converters to execute command: {selectedCommand.label}.
              </div>
              {convertersArray.length > 0 ? (
                <div className='rack-choose-list'>
                  <div id='first-cell'>
                    <button
                      onClick={handleClickSelectAll}
                    >
                      Select All
                    </button>
                    <button onClick={handleClickUnselectAll}>
                      Unselect All
                    </button>
                  </div>
                  {convertersArray.map((converter) =>
                    <div
                      key={`converter-cheeckbox-${converter}`}
                      className='item'
                      onClick={() => handleClickCheckBox(converter)}
                    >
                      <div className={`input-checkbox ${selectedConverters.includes(converter) ? 'act' : ''}`}/>
                      <div>
                        {`${selectedCommand.unit}${converter}`}
                      </div>
                    </div>
                  )}
                </div>
              ) : (
                <div style={{textAlign: 'center', marginTop: '10px'}}>
                  {'No converters online'}
                </div>
              )}
              {selectedCommand.command === 'Power' && (
                <div className='popup-row' style={{alignSelf: 'center'}}>
                  <div className='bold'>Power</div>
                  <div className='popup-table'>
                    <div className='popup-table-col'>
                      <div>Real (P):</div>
                      <div className='range-lable' />
                    </div>
                    <div className='popup-table-col'>
                      <div>
                        <input
                          onKeyDown={(e) => {
                            if (e.keyCode === 13) {
                              e.preventDefault();
                              handleClickConfirm();
                            }
                          }}
                          className={'comand-popup-inp ' + !isValidReal ? 'error' : ''}
                          type='number'
                          step='0.1'
                          value={real}
                          onMouseEnter={() => setOverInput(true)}
                          onMouseLeave={() => setOverInput(false)}
                          onChange={e => setReal(e.target.value)}
                        />
                      </div>
                      <div className='range-lable' />
                    </div>
                    <div className='popup-table-col'>
                      <div>kW</div>
                      <div className='range-lable' />
                    </div>
                  </div>
                  {!isValidReal && (
                    <div className='popup-row warn-alert'>
                      The value of real power must be between {allowedMinReal
                      } and {allowedMaxReal}
                    </div>
                  )}
                </div>
              )}
              <div className='popup-row commands-popup-control'>
                <button
                  className='bold'
                  disabled={!isValidInput}
                  onClick={() => handleClickConfirm()}
                >
                  OK
                </button>
                <button className='bold'
                  onClick={() => {
                    setSelectedCommand(null);
                    setSelectedConverters([]);
                  }}
                >
                  CANCEL
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default storeConnector(ConverterCommandButtons, {
  site: ['siteMeta'],
  service: ['popupProps'],
});
