import React from 'react';
import ReactDOM from 'react-dom';

import App from 'App';
import * as serviceWorker from 'serviceWorker';
import { Provider } from 'react-redux';
import { store } from 'store';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'assets/App.scss';

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
const EN_TRANSLATION = require('translations/en.json');
// const PT_TRANSLATION = require('translations/pt.json');
window.EN_TRANSLATION = EN_TRANSLATION;
i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: EN_TRANSLATION
      }
    },
    lng: 'en',
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false
    }
  });

function noop() { }

if (window.location.hostname !== 'localhost' && window.location.hostname !== '127.0.0.1') {
  console.log = noop;
  console.warn = noop;
  console.error = noop;
}

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
