import React, { useRef, useState } from 'react';
import { storeConnector } from 'store';
import Input from 'components/elements/Input/Input';
import { optionsSelectTZ } from 'utils';
import UserAttribueCheckBoxLine from './UserAttribueCheckBoxLine';

const SettingsPopup = (props) => {
  const [preferredTzMode, setPreferredTzMode] = useState(props.preferredTzMode);
  const [showMap, setShowMap] = useState(props.showMap);
  const [unitFaultAudioAlarmEnabled, setUnitFaultAudioAlarmEnabled] = useState(
    props.unitFaultAudioAlarmEnabled
  );
  const [unitFaultBannerAlarmEnabled, setUnitFaultBannerAlarmEnabled] = useState(
    props.unitFaultBannerAlarmEnabled
  );

  const [loading, setLoading] = useState(false);
  const darkThemeRef = useRef();
  darkThemeRef.current = props.darkTheme;

  const changeTheme = async () => {
    await props.actions.switchTheme(!darkThemeRef.current);
    if (darkThemeRef.current){
      document.querySelector('body').classList.remove('theme--light');
      document.querySelector('body').classList.add('theme--dark');
    } else {
      document.querySelector('body').classList.remove('theme--dark');
      document.querySelector('body').classList.add('theme--light');
    }

    localStorage.setItem('lightTheme', !darkThemeRef.current);
  };

  const updatePreferedTZ = async () => {
    setLoading(true);
    await props.actions.changeUserData([{
      Name: 'custom:preferred_timezone',
      Value: preferredTzMode,
    }]);
    setLoading(false);
    props.actions.updateStore({ user: {preferredTzMode: preferredTzMode}});
  };

  return (
    <div className='cover-conteiner'>
      <div className='control-popup-container'>
        <div className='title-profile'>Settings</div>
        <div className='popup-close' onClick={()=>props.close()} />
        <div className='profile-content' style={{minWidth:'450px', alignItems: 'center'}}>
          <div>Theme dark mode</div>
          <Input
            onChange={() => changeTheme()}
            checked={props.darkTheme}
            type='toggle'
            label='changeTheme'
          />
          <UserAttribueCheckBoxLine
            label='Show map view by default'
            inputLabel='hideMapForUser'
            value={showMap}
            initialValue={props.showMap}
            setValue={setShowMap}
            attributeName='showMap'
            cognitoAttributeName='custom:show_map'
            setLoading={setLoading}
          />
          <UserAttribueCheckBoxLine
            label='Enable audio alarm for units fault'
            inputLabel='unitFaultAudioAlarmEnabled'
            value={unitFaultAudioAlarmEnabled}
            initialValue={props.unitFaultAudioAlarmEnabled}
            setValue={setUnitFaultAudioAlarmEnabled}
            attributeName='unitFaultAudioAlarmEnabled'
            cognitoAttributeName='custom:fault_audio_alarm'
            setLoading={setLoading}
          />
          <UserAttribueCheckBoxLine
            label='Enable banner alarm for units fault'
            inputLabel='unitFaultBannerAlarmEnabled'
            value={unitFaultBannerAlarmEnabled}
            initialValue={props.unitFaultBannerAlarmEnabled}
            setValue={setUnitFaultBannerAlarmEnabled}
            attributeName='unitFaultBannerAlarmEnabled'
            cognitoAttributeName='custom:fault_banner_alarm'
            setLoading={setLoading}
          />
          <div>Preferred Timezone</div>
          <div style={{zIndex: '1', display: 'flex', alignItems: 'flex-start', opacity: '1'}}>
            <Input
              type='dropdown'
              placeholder='Select Device'
              ddMaxHeight='260'
              value={{value: preferredTzMode}}
              styleDD={{
                maxWidth: '60px',
                minWidth: '60px',
                marginRight: 'auto',
              }}
              onChange={(val) => setPreferredTzMode(optionsSelectTZ[val])}
              options={ optionsSelectTZ }
            />
            {
              preferredTzMode !== props.preferredTzMode ?
                <>
                  <button
                    style={{width: '56px'}}
                    className='type-small-light'
                    onClick={() =>updatePreferedTZ()}
                  >
                  Save
                  </button>
                  <button
                    style={{width: '56px'}}
                    className='type-small-light'
                    onClick={() => setPreferredTzMode(props.preferredTzMode)}
                  >
                  Cancel
                  </button>
                </>
                :
                <></>
            }
          </div>
        </div>
        {
          loading &&
          <div className='chat-spinner-holder center'>
            <div className='lds-ripple'><div /><div /></div>
          </div>
        }
      </div>
    </div>
  );
};

export default storeConnector(SettingsPopup, {
  service: ['darkTheme'],
  user: [
    'preferredTzMode',
    'showMap',
    'unitFaultAudioAlarmEnabled',
    'unitFaultBannerAlarmEnabled',
  ]
});