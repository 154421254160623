import React, { useRef, useState } from 'react';
import { storeConnector } from 'store';
import { useTranslation } from 'react-i18next';

const strongRegex = new RegExp('^(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})');

const ChangePasswordPopup = (props) => {
  const { t } = useTranslation();
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');
  const [showErrors, setShowErrors] = useState(false);
  const [loading, setLoading] = useState(false);
  const pswdInp = useRef();
  const pswdInpNew = useRef();
  const pswdInpRepeat = useRef();

  const passwordValidation = () => {
    if (!oldPassword.length){
      return 'Old password field can`t be empty';
    }
    if (newPassword.length < 12){
      return 'Password should contain 12 or more characters';
    }
    if (!strongRegex.test(newPassword)) {
      return 'Password should contain number, capital letter, special symbol';
    }
    if (newPassword !== repeatPassword) {
      return 'Password and its confirmation are not identical';
    }
    return true;
  };

  const changePassword = () => {
    if(passwordValidation() === true) {
      setLoading(true);
      props.actions.changePass({
        oldPass: oldPassword,
        newPass: newPassword,
      }).then(() => {
        props.close();
        props.actions.notify('Changes were saved successfully');
      }).catch((err)=>{
        setLoading(false);
        props.actions.notifyError(`${err.message}`);
      });
    } else {
      setShowErrors(true);
    }
  };

  return (
    <div className='cover-conteiner'>
      <div className='control-popup-container'>
        <div className='title-profile'>{t('CHANGE_PASSWORD')}</div>
        <div className='popup-close' onClick={()=>props.close()} />
        <div className='profile-content pswd'>
          <div>{t('OLD_PASSWOORD')}</div>
          <div>
            <input
              ref={pswdInp}
              style={{width:'100%'}}
              type='password'
              name='password'
              placeholder='********'
              onChange={(e) => setOldPassword(e.target.value)}
              onKeyDown={(e) => {
                if (e.keyCode === 13) {
                  if (!newPassword) {
                    pswdInpNew.current.focus();
                  } else if (!repeatPassword) {
                    pswdInpRepeat.current.focus();
                  } else {
                    changePassword();
                  }
                  e.preventDefault();
                }
              }}/>
          </div>
          <div>{t('NEW_PASSWORD')}</div>
          <div>
            <input
              ref={pswdInpNew}
              style={{width:'100%'}}
              type='password'
              name='password'
              placeholder='********'
              onChange={(e) => setNewPassword(e.target.value)}
              onKeyDown={(e) => {
                if (e.keyCode === 13) {
                  if (!repeatPassword) {
                    pswdInpRepeat.current.focus();
                  } else if (!oldPassword) {
                    pswdInp.current.focus();
                  } else {
                    changePassword();
                  }
                  e.preventDefault();
                }
              }}/>
          </div>
          <div>{t('CONFIRM_PASSWORD')}</div>
          <div>
            <input
              ref={pswdInpRepeat}
              style={{width:'100%'}}
              type='password'
              name='password'
              placeholder='********'
              onChange={(e) => setRepeatPassword(e.target.value)}
              onKeyDown={(e) => {
                if (e.keyCode === 13) {
                  if (!oldPassword) {
                    pswdInp.current.focus();
                  } else if (!newPassword) {
                    pswdInpNew.current.focus();
                  } else {
                    changePassword();
                  }
                  e.preventDefault();
                }
              }}/>
          </div>
          <div className='error'>
            {(showErrors && (passwordValidation() !== true)) ?
              passwordValidation() : ''}
          </div>
          <div>
            <button
              onClick={() => props.close()}
              style={{width:'100%'}}>
              {t('CANCEL')}
            </button>
          </div>
          <div>

            <button
              onClick={() => { changePassword(); }}
              style={{width:'100%'}}
            >
              {t('SAVE')}
            </button>
          </div>
        </div>
        {
          loading &&
          <div className='chat-spinner-holder center'>
            <div className='lds-ripple'><div /><div /></div>
          </div>
        }

      </div>
    </div>
  );
};

export default storeConnector(ChangePasswordPopup, {});