import React, { useEffect, useRef, useState } from 'react';
import 'components/elements/Input/Input.scoped.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Input = (props) => {
  const [ddActive, setDDActive] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [ddDirectionBottom, setDDDirectionBottom] = useState(true);
  const wrapperRef = useRef();
  const timeHoursRef = useRef();
  const timeMinutesRef = useRef();

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    if (props.type === 'time') {
      const time = getTimeStrs();
      timeHoursRef.current.value = time[0];
      timeMinutesRef.current.value = time[1];
    }
    return () => document.removeEventListener('mousedown', handleClickOutside);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClickOutside = (event) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      if (['dropdown', 'multiselect-dropdown', 'dropdown-multilevel'].includes(props.type)) {
        setDDActive(false);
      }
    }
  };
  const getMulttilevelValueText = () => {
    const opts = JSON.parse(JSON.stringify(props.options));
    return opts.map(opt => {
      if (opt.inputs && opt.isActive) {
        return opt.label + '(' + opt.inputs.map(inp => `${inp.label}:${inp.value}`).join(', ') + ')';
      } else if (opt.options && opt.options.length && opt.options.find(o => o.isActive)) {
        return opt.label + '(' + opt.options.filter(o => o.isActive).map(inp => inp.label)
          .join(', ') + ')';
      } else if (!opt.options && opt.isActive) {
        return opt.label;
      } else {
        return false;
      }
    }).filter(o => o !== false).join('\r\n');
  };
  const setMulttilevelValue = (action, params) => {
    const opts = JSON.parse(JSON.stringify(props.options));
    if (action === 'showSubOpt') {
      opts[params.index].isActive = params.value;
    }
    if (action === 'checkSubOpt') {
      opts[params.index].options[params.indexSub].isActive =
        !opts[params.index].options[params.indexSub].isActive;
    }
    props.onChange(opts);
  };
  const isMultiSelectOptionActive = (opt) => {
    if (opt.isActive && !opt.options) {
      return true;
    }
    if (opt.options && opt.options.find(o => o.isActive)) {
      return true;
    }
    return false;
  };
  const selectUnselectAllSub = (value, index) => {
    const opts = JSON.parse(JSON.stringify(props.options));
    opts[index] = {
      ...opts[index],
      options: opts[index].options.map(o => ({ ...o, isActive: value }))
    };
    props.onChange(opts);
  };
  const onChangeInpMultilevel = (value, index, indexSub) => {
    const opts = JSON.parse(JSON.stringify(props.options));
    opts[index] = {
      ...opts[index],
      inputs: opts[index].inputs
        .map((inp, i) => ({ ...inp, value: i === indexSub ? value : inp.value })),
    };
    props.onChange(opts);
  };
  const handleDDActive =() => {
    if (!ddActive) {
      const bodyHeight = document.body.clientHeight;
      const rect = document.getElementById('dd-inp-for-direction').getBoundingClientRect();
      const yBottom = rect.y + rect.height;
      const ddMaxHeight = props.ddMaxHeight || 106;
      if (ddMaxHeight + yBottom > bodyHeight) {
        setDDActive(ddActive => !ddActive);
        setDDDirectionBottom(false);
      } else {
        setDDActive(ddActive => !ddActive);
        setDDDirectionBottom(true);
      }
      return;
    }
    setDDActive(ddActive => !ddActive);
  };

  const getTimeStrs = () => {
    if (props.type === 'time' && (props.value || props.value === 0)) {
      const hours = Math.floor(+props.value / 3600);
      const minutes = (props.value - hours * 3600) / 60;
      return [hours || '0', minutes || '00'];
    }
    return ['0', '00'];
  };
  const formatValue = (index, val) => {
    if (index === 0) {
      return +val;
    }
    if (index === 1) {
      if (+val <= 59) {
        return +val;
      }
      if (+val / 100 >= 1) {
        const newVal = ('' + val).substring(0, 2);
        if (+newVal <= 59) {
          return +newVal;
        }
        return 59;
      }
    }
  };
  const onChangetime = (ref, index) => {
    const hours = formatValue(0, timeHoursRef.current.value);
    const minutes = formatValue(1, timeMinutesRef.current.value);
    const newTime = hours * 3600 + minutes * 60;
    ref.current.value = index === 0 ? hours : minutes;
    props.onChange(newTime);
  };

  const { type, options, value, placeholder, objModeLabel, afterOptBtn, error,
    disabled, className, showPasswordIcon, noAutocomplete, disabledOptions, styleDD,
    rememberScrollPosition, label, onClick, key, onMouseEnter, onMouseLeave
  } = props;
  return (
    <>
      {type === 'smartsearchinput' &&
        // <div
        //   style={{ ...inputStyle }}
        //   className={`${className} ${disabled && 'disabled'} inp-container-gen dir-btm-${ddDirectionBottom} input-scrollbar`}
        //   ref={wrapperRef}>
        //   {label && <div class='hint-label'>{label}</div>}
        <span id='dd-inp-for-direction' className={`smart-search-input ${disabled && 'disabled'} input-field ${ddActive ? 'act' : ''} ${className}`}>
          <>
            <input key={key}
              onClick={onClick}
              className={`${disabled && 'disabled'} inp-container-gen  input-field ${ddActive ? 'act' : ''} ${className}`}
              ref={wrapperRef}
              type='text'
              autoComplete={noAutocomplete ? 'new-password' : ''}
              value={value}
              disabled={disabled}
              placeholder={placeholder}
              onChange={(e) => props.onChange(e.target.value)}
            />
          </>
        </span>
      }
      {/* </div> */}

      {
        type === 'dropdown' &&
        <div
          style={styleDD || {}}
          className={`${className} ${disabled && 'disabled'} inp-container-gen dir-btm-${ddDirectionBottom} input-scrollbar`}
          ref={wrapperRef}>
          {label && <div class='hint-label'>{label}</div>}
          <div className={`input-field ${ddActive ? 'act' : ''}`} onClick={() => handleDDActive()} id='dd-inp-for-direction'>
            <>
              {
                objModeLabel ?
                  <div>{value ? ((typeof value === 'string') ? value : value[objModeLabel]) : (placeholder || ' ')}</div>
                  :
                  <div className={`${[null, undefined, false].includes(value) && 'placeholder'}`}>
                    <span title={([null, undefined, false].includes(value) && placeholder && placeholder) || (value.value || (value.index !== undefined ? options[value.index] : ''))}>
                      {([null, undefined, false].includes(value) && placeholder && placeholder) || (value.value || (value.index !== undefined ? options[value.index] : ''))}
                    </span>
                  </div>
              }
            </>
            <div className={`cntrl-btn ${ddActive ? 'close-cntrl' : 'open'}`} />
          </div>
          {
            (options?.length && (ddActive || rememberScrollPosition)) ?
              <div className={`dd-options${ddActive ? '' : ' dd-hidden'}`}
                style={props.ddMaxHeight ? { maxHeight: props.ddMaxHeight + 'px' } : {}}
              >
                {
                  options.map((opt, i) =>
                    <div key={i} className={`dd-opt ${disabledOptions && disabledOptions.includes(opt) && 'disabled-opt'}`} onClick={() => {
                      if (disabledOptions && disabledOptions.includes(opt)) {
                        return;
                      }
                      props.onChange(objModeLabel ? opt : i);
                      setDDActive(false);
                    }}
                    title={`${!objModeLabel && !(disabledOptions && disabledOptions.includes(opt)) ? opt : ''}`}

                    >
                      {objModeLabel ? (opt[objModeLabel] || opt) : opt}{
                        afterOptBtn && Object.keys(opt).includes(afterOptBtn.field) &&
                        <div onClick={(e) => { e.stopPropagation(); afterOptBtn.onClick(opt); }}
                          className={`after-btn ${opt[afterOptBtn.field] ?
                            afterOptBtn.activeIconClass : afterOptBtn.nonActiveIconClass} `
                          }
                        />}
                    </div>
                  )
                }
              </div>
              :
              <></>
          }
        </div>
      }
      {
        type === 'multiselect-dropdown' &&
        <div
          style={styleDD || {}}
          className={`${className} ${disabled && 'disabled'} inp-container-gen dir-btm-${ddDirectionBottom}`}
          ref={wrapperRef}>
          <div className={`input-field ${ddActive ? 'act' : ''}`}
            onClick={() => handleDDActive()} id='dd-inp-for-direction'
          >
            <>
              {
                <div className={`${!value.length && 'placeholder'}`}>
                  <span title={!value.length ? (placeholder || '') : value.join(', ')}>
                    {!value.length ? (placeholder || '') : value.join(', ')}
                  </span>
                </div>
              }
            </>
            <div className={`cntrl-btn ${ddActive ? 'close-cntrl' : 'open'}`} />
          </div>
          {
            (options.length && ddActive) ?
              <div className='dd-options' style={props.ddMaxHeight ? { maxHeight: props.ddMaxHeight + 'px' } : {}}>
                {
                  options.map((opt, i) =>
                    <div key={i} className={`dd-opt ${value.includes(opt) && 'multiselect-choosen'}`} onClick={() => {
                      props.onChange(value.includes(opt) ?
                        value.filter(v => v !== opt) : [...value, opt]);
                    }}
                    title={opt}
                    >
                      {opt}
                    </div>
                  )
                }
              </div>
              :
              <></>
          }
        </div>
      }
      {
        type === 'number' &&
        <div className={`inp-container-text ${className}`}>
          <div className='col-inp-container'>
            {error &&
              <div className='err'>{error || ''}</div>
            }
            <input
              className={`${error && 'with-error'}`}
              type='number'
              value={value}
              disabled={disabled}
              onChange={(e) => {
                if (e.target.value && ('' + e.target.value).length < 5) {
                  props.onChange(e.target.value);
                }
              }}
              placeholder={placeholder}
            />
          </div>
        </div>
      }
      {
        ['text', 'password'].includes(type) &&
        <div className={`inp-container-text ${className}`}>
          <div className='col-inp-container'>
            <div className='err'>{error || ''}</div>
            <input
              onMouseEnter={onMouseEnter}
              onMouseLeave={onMouseLeave}
              className={`${error && 'with-error'}`}
              ref={wrapperRef}
              type={showPassword ? 'text' : type}
              autoComplete={noAutocomplete ? 'new-password' : ''}
              value={value}
              disabled={disabled}
              placeholder={placeholder}
              onChange={(e) => {
                if (props.onChange(e.target.value)) {
                  props.onChange(e.target.value);
                }
              }}
            />
          </div>
          {
            type === 'password' && showPasswordIcon &&
            <div className='show-pass' onClick={() => setShowPassword(showPassword=>!showPassword)}>
              {
                showPassword ?
                  <FontAwesomeIcon icon={['fas', 'eye-slash']} />
                  :
                  <FontAwesomeIcon icon={['fas', 'eye']} />
              }
            </div>
          }
          <div />
        </div>
      }
      {
        type === 'checkbox' &&
        <div
          onClick={() => props.onChange()}
          className={`input-checkbox ${disabled && 'disabled'} ${className} ${value ? 'act' : ''}`}
        />
      }
      {
        type === 'time' &&
        <div className='inp-container-text time'>
          <span>
            <input
              type='number'

              ref={timeHoursRef}
              placeholder='dd'
              onChange={(e) => onChangetime(timeHoursRef, 0)}
            />
            <span>hours</span>
          </span>

          &nbsp; : &nbsp;
          <span>
            <input
              type='number'

              placeholder='hh'
              ref={timeMinutesRef}
              max={59}
              onChange={(e) => onChangetime(timeMinutesRef, 1)}
            />
            <span>minutes</span>
          </span>
        </div>
      }
      {
        type === 'dropdown-multilevel' &&
        <div className={`${className} ${ddActive ? 'act' : ''} ${disabled && 'disabled'} inp-container-gen`} ref={wrapperRef}>
          <div className={`input-field ${ddActive ? 'act' : ''}`}
            onClick={() => setDDActive(ddActive =>! ddActive)}
          >
            <>
              {
                <div style={{ whiteSpace: 'pre-line' }}>{getMulttilevelValueText() || <span style={{ opacity: '0.6' }}>{placeholder || ''}</span>}</div>
              }
            </>
            {
              ddActive ?
                <FontAwesomeIcon icon='chevron-up' />
                :
                <FontAwesomeIcon icon='chevron-down' />
            }
          </div>
          {
            options.length && ddActive &&
            <div className='dd-options'>
              {
                options.map((opt, i) =>
                  <div key={i} className={`dd-opt ${isMultiSelectOptionActive(opt) && 'checked'}`}
                  >
                    <div className='opt-label' key={i} onClick={() => setMulttilevelValue('showSubOpt', { index: i, value: !opt.isActive })}>
                      {
                        !opt.options &&
                        <div
                          className={`input-checkbox ${opt.isActive ? 'act' : ''}`}
                        />
                      }
                      {opt.label}
                      {
                        opt.options &&
                        <>
                          {
                            opt.isActive ?
                              <FontAwesomeIcon
                                className='auto-mrg-l'
                                icon='angle-double-up'
                              />
                              :
                              <FontAwesomeIcon
                                className='auto-mrg-l'
                                icon='angle-double-down'
                              />
                          }
                        </>
                      }
                    </div>
                    {
                      opt.isActive && opt.inputs &&
                      <div className='inputs'>
                        {
                          opt.inputs.map((inp, ind) =>
                            <input
                              type='text'
                              key={ind}
                              value={inp.value}
                              onChange={(e) => onChangeInpMultilevel(e.target.value, i, ind)}
                              placeholder={inp.label}
                            />
                          )
                        }
                      </div>
                    }
                    {
                      opt.isActive && opt.options && opt.options.length &&
                      <>
                        <div className='sub-opt'>
                          <span className='btn-check-all' onClick={() => (selectUnselectAllSub(true, i))}>
                            Select All
                          </span>
                          <span>&nbsp;/&nbsp;</span>
                          <span className='btn-check-all' onClick={() => (selectUnselectAllSub(false, i))}>
                            Unselect All
                          </span>
                        </div>
                        {
                          opt.options.map((o, k) =>
                            <div className='sub-opt' key={k}>
                              <div
                                onClick={() => setMulttilevelValue('checkSubOpt', { index: i, indexSub: k })}
                                className={`input-checkbox ${o.isActive ? 'act' : ''}`}
                              />
                              {o.label}</div>
                          )
                        }
                      </>

                    }
                  </div>
                )
              }
            </div>
          }
        </div>

      }
      {
        type === 'toggle' &&
        <div className='toggle' style={styleDD || {}}>
          <div className='toggle-button'>
            <input type='checkbox'
              className='checkbox'
              name={props.label}
              id={props.label}
              defaultChecked={props.defaultChecked}
              checked={props.checked}
              onChange={props.onChange}
            />
            <label className='label' htmlFor={props.label}>
              <span className='inner' />
              <span className='circle' />
            </label>
          </div>
        </div>
      }
    </>
  );
};

export default Input;