import React from 'react';
import 'components/elements/Windmill/Windmill.scoped.scss';
import {ReactComponent as D1} from 'assets/404-1-01.svg';
import {ReactComponent as D2} from 'assets/404-2-01.svg';

const Windmill = props => {
  const style = {
    ...(props.opacity ? { opacity: props.opacity } : {}),
    ...(props.top ? { top: props.top } : {}),
    ...(props.left ? { left: props.left } : {}),
    ...(props.scale ? { transform: `scale(${props.scale})` } : {}),
    ...(props.right ? { left: 'unset', right: props.right } : {}),
    ...(props.bottom ? { top: 'unset', bottom: props.bottom } : {}),
  };
  return (
    <div className='wind-mill' style={style}>
      <div className='part1'>
        <D1 />
      </div>
      <div className='part2'>
        <D2 />
      </div>
    </div>
  );
};

export default Windmill;