import React from 'react';
import UnitsFaultAlarm from './UnitsFaultAlarm';

function SystemAlarm() {
  return (
    <>
      <UnitsFaultAlarm />
    </>
  );
}

export default SystemAlarm;