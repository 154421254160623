import React, { useState } from 'react';
import 'components/Battery/OperationsAndMonitoring/SiteUnitComandsPopup/SiteUnitComandsPopup.scoped.scss';
import { storeConnector } from 'store';
import Draggable from 'react-draggable';
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import CommandsSection from './CommandsSection';
import CellBalancingSection from './CellBalancingSection';
import CommandsExecuteConfirmModal from './CommandsExecuteConfirmModal';
import ConverterCommandsSection from './ConverterCommandsSection';
import AdvancedUnitCommandsSection from './AdvancedUnitCommandsSection';
import AdvancedSiteCommandsSection from './AdvancedSiteCommandsSection';

const SiteUnitComandsPopup = (props) => {
  const [loading, setLoading] = useState(false);
  const [overInput, setOverInput] = useState(false);
  const [activeTab, setActiveTab] = useState('commands');
  const [commandsToExecute, setCommandsToExecute] = useState([]);
  const [onCommandsExecute, setOnCommandsExecute] = useState(undefined);
  const [onCommandsCancel, setOnCommandsCancel] = useState(undefined);

  const replaceNulls = () => {
    return unitName.replace(/^0+/, '');
  };

  const unitName = props.popupProps.obj.UnitName;

  const hasCellBalancingSection = props.popupProps?.popupType === 'unit' && +props.siteMeta?.Enable_CellBalancingMode === 1;
  const hasConvertersSection = props.popupProps?.popupType === 'unit' && +props.siteMeta?.NumConverters > 0;
  const hasAdvancedSection = +props.siteMeta?.Enable_Advanced_Cmd === 1
    && props.user?.hasSuperPermission === true;
  const hasTabLayout = hasCellBalancingSection || hasConvertersSection || hasAdvancedSection;

  const showExecuteCommandsPopup = (commands, onExecute, onCancel) => {
    setCommandsToExecute(commands);
    setOnCommandsExecute(onExecute);
    setOnCommandsCancel(onCancel);
  };

  return (
    <div className='cover-conteiner'>
      <Draggable bounds='body' disabled={overInput}>
        <div className='control-popup-container' style={{minWidth:'540px', minHeight:'400px'}}>
          {
            loading &&
          <div className='spinner-holder'>
            <div className='lds-ripple'><div /><div /></div>
          </div>
          }
          {
            (props.popupProps.popupType === 'site' &&
            <div className='title-line'>Site Commands</div>) ||
            (props.popupProps.popupType === 'unit' && unitName &&
            <div className='title-line'>Unit #{replaceNulls(unitName)} {hasTabLayout ? '' : 'Commands'}</div>)
          }
          <div className='popup-close'
            onClick={() => props.actions.showComandsPopup(false, {})}
          />

          <div className='site-unit-commands-popup-wrapper'>
            {hasTabLayout && (
              <Nav tabs>
                <NavItem>
                  <NavLink
                    className={activeTab === 'commands' ? 'active' : ''}
                    onClick={() => setActiveTab('commands')}
                  >
                    Commands
                  </NavLink>
                </NavItem>
                {hasCellBalancingSection && (
                  <NavItem>
                    <NavLink
                      className={activeTab === 'cell-balancing' ? 'active' : ''}
                      onClick={() => setActiveTab('cell-balancing')}
                    >
                      Cell Balancing Mode
                    </NavLink>
                  </NavItem>
                )}
                {hasConvertersSection && (
                  <NavItem>
                    <NavLink
                      className={activeTab === 'converters' ? 'active' : ''}
                      onClick={() => setActiveTab('converters')}
                    >
                      Converters
                    </NavLink>
                  </NavItem>
                )}
                {hasAdvancedSection && (
                  <NavItem>
                    <NavLink
                      className={activeTab === 'advanced' ? 'active' : ''}
                      onClick={() => setActiveTab('advanced')}
                    >
                      Advanced
                    </NavLink>
                  </NavItem>
                )}
              </Nav>
            )}
            <TabContent activeTab={activeTab}>
              <TabPane tabId='commands'>
                <CommandsSection
                  setLoading={setLoading}
                  overInput={overInput}
                  setOverInput={setOverInput}
                  showExecuteCommandsPopup={showExecuteCommandsPopup}
                />
              </TabPane>
              {hasCellBalancingSection && (
                <TabPane tabId='cell-balancing'>
                  <CellBalancingSection
                    setOverInput={setOverInput}
                    showExecuteCommandsPopup={showExecuteCommandsPopup}
                  />
                </TabPane>
              )}
              {hasConvertersSection && (
                <TabPane tabId='converters'>
                  <ConverterCommandsSection
                    setOverInput={setOverInput}
                    showExecuteCommandsPopup={showExecuteCommandsPopup}
                  />
                </TabPane>
              )}
              {hasAdvancedSection && (
                <TabPane tabId='advanced'>
                  {props.popupProps?.popupType === 'unit' ? (
                    <AdvancedUnitCommandsSection
                      showExecuteCommandsPopup={showExecuteCommandsPopup}
                    />
                  ) : (
                    <AdvancedSiteCommandsSection
                      showExecuteCommandsPopup={showExecuteCommandsPopup}
                    />
                  )}
                </TabPane>
              )}
            </TabContent>
          </div>
        </div>
      </Draggable>
      {commandsToExecute.length > 0 && (
        <Draggable>
          <CommandsExecuteConfirmModal
            commandsToExecute={commandsToExecute}
            setCommandsToExecute={setCommandsToExecute}
            onExecute={onCommandsExecute}
            onCancel={onCommandsCancel}
          />
        </Draggable>
      )}
    </div>
  );
};

export default storeConnector(SiteUnitComandsPopup, {
  service: ['popupProps'],
  devices: ['batteryTable','lotoTable'],
  site: ['siteMeta'],
  user: ['user','timeMode'],
});