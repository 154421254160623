
import Dexie from 'dexie';

export default class ClientCache {

    constructor(dbName, schema) {
        this.dbName = dbName;
        const db = new Dexie(dbName);
        db.version(1).stores({ historianData: 'category' });
        this.db = db;
    }

    async putRecord(key, table, value) {
        return await this.db[table].put({...key, value});
    }

    async getRecord(key, table) {
        const result = await this.db[table].get(key);
        return result;
    }

    async getHistorianData(key) {
        return await this.getRecord({category: key.category}, "historianData");
    }

    async setHistorianData(key, value) {
        return await this.putRecord(key, "historianData", value);
    }
}